import PropTypes from 'prop-types'
import React from 'react'
import WorklistModal from '../components/WorklistModal'
import * as FadeIn from '../components/fadeIn'
import pic_fujii from '../images/Fujii_Naotaka.gif'
import pic_yoshida from '../images/yoshida.jpg'
import pic_toyoda from '../images/Toyoda_Keisuke.jpg'
import pic_uka from '../images/Todoli_Uka.png'
import pic_ochiai from '../images/Ochiai_Yoichi.jpg'
import picBanjo from '../images/Banjo_Kanna.jpg'
import picEcochin from '../images/ecochin.png'
import picSabakichi from '../images/knshtyk.jpg'
import picLilea from '../images/lileaLab.jpg'
import picMasanage from '../images/tasklong.jpg'
import pic01 from '../images/01.png'
import pic02 from '../images/02.png'
import pic03 from '../images/03.png'
import pic04 from '../images/04.png'
import pic05 from '../images/05.png'
import pic07 from '../images/07.png'
import pic08 from '../images/08.png'
import pic09 from '../images/09.png'
import pic10 from '../images/10.png'
import pic11 from '../images/11.png'
import pic12 from '../images/12.png'
import pic13 from '../images/13.png'
import pic14 from '../images/14.png'
import pic15 from '../images/15.png'
import pic16 from '../images/16.png'
import pic17 from '../images/17.png'
import pic18 from '../images/18.png'
import pic19 from '../images/19.png'
import pic20 from '../images/20.png'
import pic21 from '../images/21.png'
import pic22 from '../images/22.png'
import pic23 from '../images/23.png'
import pic24 from '../images/24.png'
import pic25 from '../images/25.png'
import pic26 from '../images/26.png'
import pic27 from '../images/27.png'
import pic28 from '../images/28.png'
import pic29 from '../images/29.png'
import pic30 from '../images/30.png'
import pic31 from '../images/31.png'
import pic32 from '../images/32.png'
import pic33 from '../images/33.png'
import pic34 from '../images/34.png'
import pic35 from '../images/35.png'
import pic36 from '../images/36.png'
import pic37 from '../images/37.png'
import pic38 from '../images/38.png'
import pic39 from '../images/39.png'
import pic40 from '../images/40.png'
import pic41 from '../images/41.png'
import pic42 from '../images/42.png'
import pic43 from '../images/43.png'
import pic44 from '../images/44.png'
import pic45 from '../images/45.png'
import pic46 from '../images/46.png'
import pic47 from '../images/47.png'
import pic48 from '../images/48.png'
import pic49 from '../images/49.png'
import pic50 from '../images/50.png'
import pic51 from '../images/51.png'
import pic52 from '../images/52.png'
import pic53 from '../images/53.png'
import pic54 from '../images/54.png'
import pic55 from '../images/55.png'
import pic56 from '../images/56.png'
import pic57 from '../images/57.png'
import pic58 from '../images/58.png'
import pic59 from '../images/59.png'
import pic60 from '../images/60.png'
import pic61 from '../images/61.png'
import pic62 from '../images/62.png'
import pic63 from '../images/63.png'
import pic64 from '../images/64.png'
import pic65 from '../images/65.png'
import pic66 from '../images/66.png'
import pic67 from '../images/67.png'
import pic68 from '../images/68.png'
import pic69 from '../images/69.png'
import pic70 from '../images/70.png'
import pic71 from '../images/71.png'
import pic72 from '../images/72.png'
import pic73 from '../images/73.png'
import pic74 from '../images/74.png'
import pic75 from '../images/75.png'
import pic77 from '../images/77.png'
import pic78 from '../images/78.png'
import pic79 from '../images/79.png'
import pic80 from '../images/80.png'
import pic81 from '../images/81.png'
import pic82 from '../images/82.png'
import pic83 from '../images/83.png'
import pic84 from '../images/84.png'
import pic85 from '../images/85.png'
import pic86 from '../images/86.png'
import pic87 from '../images/87.png'
import pic88 from '../images/88.png'
import pic89 from '../images/89.png'
import pic90 from '../images/90.png'

class Worklist extends React.Component {

    render() {
        return (
            <div>
                <div className="worklisttitle">全提出作品リスト（全89作品）</div>
                <div className="worklistexception">VRAA02に提出された全89作品のリストです。</div>
                <div className="worklist">
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">01</div><div className="workinformation"><div className="worktitle">穏やかな泉</div><div className="workname">ねころ</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                        <WorklistModal
                            title='穏やかな泉'
                                creator='ねころ'
                                number='01'
                                backgroundimage={pic01}
                                worldurl='https://vrchat.com/home/world/wrld_7db68ea4-8641-461c-911d-8da9d835e87b'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                            commentatortwitttername='@banjokannna'
                            commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='美しい山間の渓流で心がとてもリフレッシュする。完全に安全な川遊びがいずれ流行るかもしれない。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                            commentatortwitttername2='@ecochin'
                            commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='美しい川があり、癒しの一時を得る事がそこにはある。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                            commentatortwitttername3='@tasklong'
                            commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='柔らかな光と清流の優しい音に落ち着く。そして目の前に迫る巨大な滝を見ると、思わず滝つぼや滝裏を探索したい欲求にかられた。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_7db68ea4-8641-461c-911d-8da9d835e87b" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                    </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">【 穏やかな泉 Calm Fountain jp 】<br />森林の中に広がる滝つぼをイメージしました。<br /><br />岩とHalo(靄)を使用し、スポーン地点から直線に滝本体が見えないよう工夫しています。<br />アバターが腰より浸からない・溺れないために地面(岩)の高さを整えています。<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://twitter.com/hashtag/VRAA02?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02</a> <a href="https://t.co/56U7LoV4LH">pic.twitter.com/56U7LoV4LH</a></p>&mdash; ねころ@VRC (@tsubasa9317) <a href="https://twitter.com/tsubasa9317/status/1275785355002699777?ref_src=twsrc%5Etfw">June 24, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">02</div><div className="workinformation"><div className="worktitle">橋</div><div className="workna"><div className="workname">ユシド</div><div className="award">佳作</div></div></div></div><div className="worklist_detail">
                        <WorklistModal
                            title='橋'
                            creator='ユシド'
                            number='02'
                            backgroundimage={pic02}
                            worldurl='https://vrchat.com/home/world/wrld_f0d0a1d0-f97c-4bda-a0f5-29a20f6b4cfa'
                            class="commentbox"
                            commentator='番匠カンナ'
                            photo={picBanjo}
                            commentatortwitttername='@banjokannna'
                            commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                            comment='大人数で死ぬのが楽しくなってくる不思議な体験。意図的なのかわからないキューブの存在が死を駆り立てる。'
                            class2="commentbox"
                            commentator2='えこちん'
                            photo2={picEcochin}
                            commentatortwitttername2='@ecochin'
                            commentatortwittterurl2='https://twitter.com/ecochin'
                            comment2='バーチャルでの死とは何かを問うてくる。最初こそ恐れをもって飛び込むが暫くすると好奇心が芽生え、進んで飛び込んで死んで俯瞰するようになるのは非常に興味深い。'
                            class3="commentbox"
                            commentator3='sabakichi'
                            photo3={picSabakichi}
                            commentatortwitttername3='@knsthtyk'
                            commentatortwittterurl3='https://twitter.com/knshtyk'
                            comment3='死はそれ自体には何の意味も持たない。そこに意味を見出そうとするのは我々が意味を欲しているからだ。バーチャルで死ぬということが一体何を意味するのか、身投げという自発的な動作によって死んだという記号がただ虚しくシステムとして提供される。対比的に橋の上が生の領域となるわけだが、ほとんどの人は死ばかりを意識するというのも示唆的だった。'
                            class4="commentbox"
                            commentator4='masanaga'
                            photo4={picMasanage}
                            commentatortwitttername4='@tasklong'
                            commentatortwittterurl4='https://twitter.com/tasklong'
                            comment4='自分の死の俯瞰、他者の死の傍観。目の前に倒れ往く人を見て自分は何もできない。ただそこに花が添えられるのみ。その光景がいかにも「バーチャル」を感じさせる。'
                            class5="commentbox_invisible"
                            class6="commentbox_invisible"
                            class7="commentbox_invisible"
                        /><div className="worklist-button">
                            <a href="https://vrchat.com/home/world/wrld_f0d0a1d0-f97c-4bda-a0f5-29a20f6b4cfa" target="__blank"><button>
                                ワールドへ行く</button></a>
                        </div>
                    </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">景色と光景があります。<br />攻撃的な諦めの感情による産物。<br />ゲームのプレイスタイルってプレイヤーの様々な価値観が反映されますよね。<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a></p>&mdash; ユシド (@yushid_h) <a href="https://twitter.com/yushid_h/status/1272115054389850114?ref_src=twsrc%5Etfw">June 14, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"> <FadeIn.Up><div className="information"><div className="number">03</div><div className="workinformation"><div className="worktitle">VRHome</div><div className="workna"><div className="workname">Hyakuashi</div><div className="award">佳作</div></div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='VRHome'
                                creator='Hyakuashi'
                                number='03'
                                backgroundimage={pic03}
                                worldurl='https://vrchat.com/home/world/wrld_942cf869-f9a4-4371-8322-fb3c2e75b2cd'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='VRinVRな体験。ヘッドマウントディスプレイを被る行為という今現在の制約を取っ払ったらどういう表現になるか気になった。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='普段何気なくしているVR体験を俯瞰することができる不思議な世界。'
                                class3="commentbox"
                                commentator3='sabakichi'
                                photo3={picSabakichi}
                                commentatortwitttername3='@knsthtyk'
                                commentatortwittterurl3='https://twitter.com/knshtyk'
                                comment3='構造としてはバーチャルバーチャルリアリティだが、VRユーザ的なバ美肉的チェンジを組み合わせる＝身体の変質を多重の状態にすることで新たな感覚を生み出している。確かに「ちょっと面白い」が、人によっては強烈な体験となるだろう。'
                                class4="commentbox"
                                commentator4='masanaga'
                                photo4={picMasanage}
                                commentatortwitttername4='@tasklong'
                                commentatortwittterurl4='https://twitter.com/tasklong'
                                comment4='「VR空間はVRでのリアル」という大前提を覆される。テーマはVRinVRだが、部屋に置かれたHMDで遊んでいたら実は違ったと気づいた瞬間は一種のホラーだった。そして「本物のHMD」を外した時に目に入る光景が現実なのか疑うようになるかもしれない。'
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_942cf869-f9a4-4371-8322-fb3c2e75b2cd" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br />『VRHome』<br />派手じゃないけど「ちょっと面白い」って思ってもらえるワールドを目標に作りました。ギミック有〼<br />よかったらお立ち寄りください。 <a href="https://t.co/vm0Mux9bCV">pic.twitter.com/vm0Mux9bCV</a></p>&mdash; ヒャクアシさん (@mukaderabbit) <a href="https://twitter.com/mukaderabbit/status/1272103542925352960?ref_src=twsrc%5Etfw">June 14, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">04</div><div className="workinformation"><div className="worktitle">Through The Glass / Window</div><div className="workname">POC (⋈)</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Through The Glass / Window'
                                creator='POC (⋈)'
                                number='04'
                                backgroundimage={pic04}
                                worldurl='https://vrchat.com/home/world/wrld_b7d8f556-37f5-4736-82a7-714f5e9132ff'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='現実世界とバーチャル世界をつなぐ野心的な試み。現実の空の色がリアルタイムに反映される仕組みは面白いが、天窓を見上げるバーチャル内体験の強度が弱く感じた。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='バーチャルという世界が現実世界の延長線上にあることを感じさせる世界。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='空間の一部だけが現実と繋がっている。ただそれを眺めるだけでなく、空間を照らす光となり色となりそれを全身で浴びる感覚を得られる。ハードウェアの都合ではあるが夜が暗闇になるわけではないのもまた面白い。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_b7d8f556-37f5-4736-82a7-714f5e9132ff" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br />Through the Glass/Window にて、VRAA02に参加します。<br />Through the Glass/Window は、私の家の窓から見た空の光を感じられるワールドです。<br />ワールド内の天窓が私の家のカメラとリアルタイムで繋がっており、カメラは家の窓を通して空を写しています(続く)<a href="https://t.co/tQ1mWvL1UH">https://t.co/tQ1mWvL1UH</a></p>&mdash; poc@VRChat (@pocre_VRChat) <a href="https://twitter.com/pocre_VRChat/status/1272421075767287808?ref_src=twsrc%5Etfw">June 15, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">05</div><div className="workinformation"><div className="worktitle">Leer:North Thalia</div><div className="workname">しーわん</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Leer:North Thalia'
                                creator='しーわん'
                                number='05'
                                backgroundimage={pic05}
                                worldurl='https://vrchat.com/home/world/wrld_d1ebc7a8-08a0-4c87-baca-3d54e79bcc9a'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='RPGの世界に訪れたような感覚。街は広く、ここからどんな物語が始まるかワクワクする。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='美しい街が広がっている。ゲームの世界に飛び込んだかのような感じ。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='今は建物しかないと言う感想だが、ここに様々な人が集まることで様々な街としての表情が作られるのだろう。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_d1ebc7a8-08a0-4c87-baca-3d54e79bcc9a" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">【Leer:North Thalia】VRAA02へ参加します<br /><br />VRCというゲームでありながら、ここで新しい文化、生活が生み出されるように世界観を作りました。<br /><br />また、ノースタリアはこの国の北区にすぎず、東西に伸びる道の先や遠くに見える城、城壁の外など今後の世界の広がりにも注目してください。<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/UxMW0fRnP1">pic.twitter.com/UxMW0fRnP1</a></p>&mdash; しーわん (@ABCwanVRC) <a href="https://twitter.com/ABCwanVRC/status/1273528289101877248?ref_src=twsrc%5Etfw">June 18, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">07</div><div className="workinformation"><div className="worktitle">氷の不思議な部屋</div><div className="workna"><div className="workname">甘野氷</div><div className="award">佳作</div></div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='氷の不思議な部屋'
                                creator='甘野氷'
                                number='07'
                                backgroundimage={pic07}
                                worldurl='https://vrchat.com/home/world/wrld_6ba8f836-d555-4675-8743-a7605a652774'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='アリス的な単純なスケール変化の体験なのに、大人数でぐいーんとスケール変化する瞬間がとてつもなく楽しい。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='視点を変えることで、同じ場所なのに別の世界に入るような感覚が気持ちいい。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='世界が変化するのではなく、自分に変化が起こることによって発見できる世界がある。それは今見えている世界がすべてではないことを気づかせてくれる。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_6ba8f836-d555-4675-8743-a7605a652774" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">「氷の不思議な部屋」community labにアップしました！！一見普通の部屋ですが飴玉を食べることで小さくなれます。是非とも小人になった感覚をみんなで味わってください。<a href="https://t.co/4QyRY8lCoA">https://t.co/4QyRY8lCoA</a><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://twitter.com/hashtag/VRChat_world%E7%B4%B9%E4%BB%8B?src=hash&amp;ref_src=twsrc%5Etfw">#VRChat_world紹介</a> <a href="https://twitter.com/hashtag/VRC%E3%83%90%E3%82%B9%E3%82%BF%E9%83%A8?src=hash&amp;ref_src=twsrc%5Etfw">#VRCバスタ部</a> <a href="https://t.co/5Q8SJwfwJ2">pic.twitter.com/5Q8SJwfwJ2</a></p>&mdash; 甘野氷@蕎麦プロ、バスタオル担当 (@koori2nd) <a href="https://twitter.com/koori2nd/status/1274774090457337856?ref_src=twsrc%5Etfw">June 21, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">08</div><div className="workinformation"><div className="worktitle">天空広場</div><div className="workname">ごんきち</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='天空広場'
                                creator='ごんきち'
                                number='08'
                                backgroundimage={pic08}
                                worldurl='https://vrchat.com/home/world/wrld_aa679ebb-3864-4ff9-87b2-88311a9ccb4d'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='トゥーン系表現が巧みな天空の楽園。壁にかけられた写真に写る人々が、実在する場所であることや物語の存在を強く思わせる。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='鳥の種族の街にやってきたワクワク感が良い。どのように暮らしているのか想像してしまう。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='まさに鳥人族の世界だった。二足歩行の体で訪れたこの街を満足にこの世界を楽しむことが出来るのか不安だったが、体は自由に変えられるのがこの世界だった。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_aa679ebb-3864-4ff9-87b2-88311a9ccb4d" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">「天空広場 Sky Square」をVRChatに登録しました。<br />人の世界から来る観光客を鳥の種族がおもてなしする施設というイメージです。皆さんの交流の場として使っていただけたらうれしいです。<br />VR機器持ってる人はぜひVRで見に来てね！<br /><br />ワールド各所の解説を後ほど。<a href="https://twitter.com/hashtag/VRChat_world%E7%B4%B9%E4%BB%8B?src=hash&amp;ref_src=twsrc%5Etfw">#VRChat_world紹介</a> <a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/peFyoNeF0C">pic.twitter.com/peFyoNeF0C</a></p>&mdash; ごんきち@vrchat (@gonkichi_vr) <a href="https://twitter.com/gonkichi_vr/status/1275022571960819712?ref_src=twsrc%5Etfw">June 22, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">09</div><div className="workinformation"><div className="worktitle">エンドレスサマーエンド</div><div className="workname">宮野原宮乃</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='エンドレスサマーエンド'
                                creator='宮野原宮乃'
                                number='09'
                                backgroundimage={pic09}
                                worldurl='https://vrchat.com/home/world/wrld_168334b9-fb43-44f6-9f36-7bbe6750bcfd'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='小説に描かれた情景がページを捲るたびに実際の3D空間として現れるという野心的試み。VR内で本の文字を読む難しさがあるので、そこも含めた小説体験の解体を見てみたい。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='甘酸っぱい夏の一時を登場人物と近い視点で感じることができる。お話し毎に代わっていく背景のビジュアル的な面白さは気持ちよいが、文章を読むことでリズムが落ちている気もする。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='本を手にする事で改めて作者の創作世界に没入する。ページをめくるごとに没入度が深まっていく。故にほんの少しの揺らぎで現実に引き戻されてしまう危うさも持つ世界だった。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_168334b9-fb43-44f6-9f36-7bbe6750bcfd" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">ワールド「エンドレスサマーエンド」をcommunity labに上げました<br />小説のページを繰るごとにシーンに連動して世界が切り替わります<br />自作小説の世界を再現し、自分がヒロイン役として表紙/裏表紙/挿絵を飾れたので楽しかったです<br />入室最大4人<a href="https://twitter.com/hashtag/VRChat?src=hash&amp;ref_src=twsrc%5Etfw">#VRChat</a><a href="https://twitter.com/hashtag/VRChat_world%E7%B4%B9%E4%BB%8B?src=hash&amp;ref_src=twsrc%5Etfw">#VRChat_world紹介</a> <a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/uNc5J1r7zl">pic.twitter.com/uNc5J1r7zl</a></p>&mdash; 宮野原宮乃🍋 (@yumikofukuzawa) <a href="https://twitter.com/yumikofukuzawa/status/1274644469174857728?ref_src=twsrc%5Etfw">June 21, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">10</div><div className="workinformation"><div className="worktitle">LEMURIA EVOLVES</div><div className="workname">レムリア</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='LEMURIA EVOLVES'
                                creator='レムリア'
                                number='10'
                                backgroundimage={pic10}
                                worldurl='https://vrchat.com/home/world/wrld_bce66159-89f6-48c2-84e3-4de042989f9a'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='美しい無限遠の湖に、光や音が落とし物のように点在する。不思議な世界。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='綺麗なビジュアルが広がる場所。天国のよう。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='世界の色が美しく、青空の下にあるオーロラがとても好きだった。作者が好きな物を集めた世界に入って自分もそれが好きなんだという物を見つけるのはとても嬉しい。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_bce66159-89f6-48c2-84e3-4de042989f9a" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">「 LEMURIA EVOLVES 」Labに登録しました。<br /><br /> 自分の好きなものを詰め込んでみました。歩き回って音の変化を楽しんでみてください。<br /><br /> VRAA02が終わったころにnoto等にてワールドに込めた想いとか書いてみたいです。よろしくお願いします。 <a href="https://twitter.com/hashtag/VRChat?src=hash&amp;ref_src=twsrc%5Etfw">#VRChat</a> <a href="https://twitter.com/hashtag/VRChat_world%E7%B4%B9%E4%BB%8B?src=hash&amp;ref_src=twsrc%5Etfw">#VRChat_world紹介</a> <a href="https://twitter.com/hashtag/VRAA02?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02</a> <a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/qxFcw7w68k">pic.twitter.com/qxFcw7w68k</a></p>&mdash; レムリア@くろちゃん集会 (@lemuria1392) <a href="https://twitter.com/lemuria1392/status/1276680823031267328?ref_src=twsrc%5Etfw">June 27, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">11</div><div className="workinformation"><div className="worktitle">extends</div><div className="workname">さにぃ</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='extends'
                                creator='さにぃ'
                                number='11'
                                backgroundimage={pic11}
                                worldurl='https://vrchat.com/home/world/wrld_43b3383c-8ab7-4055-b995-2e557ee9e22f'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='チーズフォンデュ楽しい。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='チーズフォンデュのチーズが伸びる感じが最高に良い。この作りによって匂いや硬さといった情報が付加されている。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='チーズの伸びが美味しそうで口に運ぶとそこには何もない。虚無を感じられる事に感動を覚える。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_43b3383c-8ab7-4055-b995-2e557ee9e22f" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://twitter.com/hashtag/MadeWithUdon?src=hash&amp;ref_src=twsrc%5Etfw">#MadeWithUdon</a><br />チーズフォンデュやチョコフォンデュが楽しめるオシャレな空間を作りました。いつもより時間がゆっくりと流れていそうです。<a href="https://t.co/UXQxfh6pVX">https://t.co/UXQxfh6pVX</a> <a href="https://t.co/YdKravf064">pic.twitter.com/YdKravf064</a></p>&mdash; さにぃ🐏ウール3% (@sunnyf6789) <a href="https://twitter.com/sunnyf6789/status/1277437421819318272?ref_src=twsrc%5Etfw">June 29, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">12</div><div className="workinformation"><div className="worktitle">TheOldBookstore"speakeasy"</div><div className="workname">マラレン</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='TheOldBookstore"speakeasy"'
                                creator='マラレン'
                                number='12'
                                backgroundimage={pic12}
                                worldurl='https://vrchat.com/home/world/wrld_157253ac-ef9d-4b2d-890d-6327c2d994e9'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='黒い本がなかなか見つからず、危うく招かれずに終わるところだった。現実世界にこそ欲しいお店。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='隠れ家的BAR。現実に存在したら行ってみたくなる。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='謎解きをした事でまるで自分が選ばれたかのように存在する空間としてとても居心地がいい。シンプルに隠し部屋なのではなく、間に通路がある事によって全く文脈の違う空間にたどり着いたのだと感じられる。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_157253ac-ef9d-4b2d-890d-6327c2d994e9" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">【TheOldBookstore&quot;speakeasy&quot;】<br />本屋なのに本屋でない本屋です。<br />どこにいても映画的な雰囲気が出るように意識して作成しました。<br />古書の香りを楽しむもよし、何かを探すもよし。<br />ゆっくりして頂けると幸いです。<a href="https://twitter.com/hashtag/VRAA02?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02</a><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/artjktcdSh">pic.twitter.com/artjktcdSh</a></p>&mdash; gai@MA=RE(マラレン) (@gai_1023) <a href="https://twitter.com/gai_1023/status/1277599355277463559?ref_src=twsrc%5Etfw">June 29, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">13</div><div className="workinformation"><div className="worktitle">#解釈不一致</div><div className="workna"><div className="workname">memex＋Mikipom</div><div className="award">Finalist</div></div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='#解釈不一致'
                                creator='memex＋Mikipom'
                                number='13'
                                backgroundimage={pic13}
                                worldurl='https://vrchat.com/home/world/wrld_21a48553-fd25-40d0-8ff0-b4402b36172a'
                                class="commentbox"
                                commentator='藤井直敬'
                                photo={pic_fujii}
                                commentatortwitttername='@NaotakaFujii'
                                commentatortwittterurl='https://twitter.com/NaotakaFujii'
                                comment='技術面、ビジュアル面いずれもVRならではの新しい挑戦がある。'
                                class2="commentbox"
                                commentator2='吉田修平'
                                photo2={pic_yoshida}
                                commentatortwitttername2='@yosp'
                                commentatortwittterurl2='https://twitter.com/yosp'
                                comment2='参加者がボーカルとギタープレイヤーを動かせることが面白いと思いました。'
                                class3="commentbox"
                                commentator3='豊田啓介'
                                photo3={pic_toyoda}
                                commentatortwitttername3='@toyoda_noiz'
                                commentatortwittterurl3='https://twitter.com/toyoda_noiz'
                                comment3='デジタル空間上ならではの知覚と認識のチャンネルで、ライブならではの親密さや距離感を再構成しているのはとても面白く、画像処理と通信制約との相乗効果がむしろ新しい表現型を与えているのもとても良い。新しいインタラクションのプロトタイプとしてとても価値があると感じた。'
                                class4="commentbox"
                                commentator4='落合陽一'
                                photo4={pic_ochiai}
                                commentatortwitttername4='@ochyai'
                                commentatortwittterurl4='https://twitter.com/ochyai'
                                comment4='空間のスケールを活かしたダイナミックな動きが印象的だった．VRパンク感のあるいい味付け．'
                                class5="commentbox"
                                commentator5='届木ウカ'
                                photo5={pic_uka}
                                commentatortwitttername5='@todoki_uka'
                                commentatortwittterurl5='https://twitter.com/todoki_uka'
                                comment5='演出も音楽も自ら手がける者として、観客をただの傍観者にしないという意気込みを感じました。'
                                class6="commentbox"
                                commentator6='番匠カンナ'
                                photo6={picBanjo}
                                commentatortwitttername6='@banjokannna'
                                commentatortwittterurl6='https://twitter.com/Banjo_Kanna'
                                comment6='ライブ当日とアーカイブで全く同じデータが再生されているのに、個人的には「本番の価値のほうが圧倒的に高かった」ことが実感としてわかった。VR以降の「ライブとは何か」問題を語る上で、必ず参照すべき作品。'
                                class7="commentbox"
                                commentator7='えこちん'
                                photo7={picEcochin}
                                commentatortwitttername7='@ecochin'
                                commentatortwittterurl7='https://twitter.com/ecochin'
                                comment7='いつ見ても最高のバーチャルライブを鑑賞することができる。ビジュアルのインパクトも相まって唯一無二の強さを持っている。'
                                class8="commentbox"
                                commentator8='sabakichi'
                                photo8={picSabakichi}
                                commentatortwitttername8='@knshtyk'
                                commentatortwittterurl8='https://twitter.com/knshtyk'
                                comment8='取り組みとしてもコンテンツの品質としても、現状見ることのできる最大のバーチャル性をもったイベントコンテンツ。'
                                class9="commentbox"
                                commentator9='masanaga'
                                photo9={picMasanage}
                                commentatortwitttername9='@tasklong'
                                commentatortwittterurl9='https://twitter.com/tasklong'
                                comment9='「過去の記録」であるはずのアーカイブが体験者の行動によって体験や記憶が変化すると言うのは、時間への干渉が行えるともいえる。それによって他者の体験や記憶とは交わらない空間が作られると言うのは一体感を求められるライブ空間を演出としては挑戦的に感じた。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_21a48553-fd25-40d0-8ff0-b4402b36172a" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">『memex live archive - <a href="https://twitter.com/hashtag/fuitch?src=hash&amp;ref_src=twsrc%5Etfw">#fuitch</a> 30 May 2020』<br />memexのワンマンライブ「<a href="https://twitter.com/hashtag/%E8%A7%A3%E9%87%88%E4%B8%8D%E4%B8%80%E8%87%B4?src=hash&amp;ref_src=twsrc%5Etfw">#解釈不一致</a>」の会場で、現在はアーカイブを体験できます。<br /><br />在るのは、欠けた頂点群、音声、ツイート越しのインタラクション。<br /><br />これを「生きている」と呼べるだろうか？<br />人をその人たらしめる要素とは何か？<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/bJEHh7VcgL">pic.twitter.com/bJEHh7VcgL</a></p>&mdash; memex - Virtual Alternative Artist (@memex_am) <a href="https://twitter.com/memex_am/status/1277960221906710533?ref_src=twsrc%5Etfw">June 30, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">14</div><div className="workinformation"><div className="worktitle">Mochi Mochi Oshare Udon Room</div><div className="workname">おもちのびる</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Mochi Mochi Oshare Udon Room'
                                creator='おもちのびる'
                                number='14'
                                backgroundimage={pic14}
                                worldurl='https://vrchat.com/home/world/wrld_54dd2145-dc7c-426f-9e40-90f5176f12d3'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='画期的な着せ替えシステムに驚いた。これまで体験した中で圧倒的にNo.1の試着体験で、これはバーチャル試着のスタンダードになってほしい。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='VRでお着換えができる。気軽にFitできるスタイルは今後デフォルトになってほしい。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='空間と言うよりは衣装カスタマイズの技術が目立つが、アバターの衣装替えがその場で出来る空間と言うのは将来性を感じるものだった。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_54dd2145-dc7c-426f-9e40-90f5176f12d3" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">着せかえができるワールド「Mochi Mochi Oshare Udon Room」をVRAA2にお出しします！たとえあなたがバターや宇宙人でも服を着ることができるはず！ぜひぜひ試してみてくださいね！<a href="https://t.co/0WhVEyiw6P">https://t.co/0WhVEyiw6P</a><a href="https://twitter.com/hashtag/VRChat?src=hash&amp;ref_src=twsrc%5Etfw">#VRChat</a><a href="https://twitter.com/hashtag/VRChat_world%E7%B4%B9%E4%BB%8B?src=hash&amp;ref_src=twsrc%5Etfw">#VRChat_world紹介</a> <a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/oEhcAqltn6">pic.twitter.com/oEhcAqltn6</a></p>&mdash; おもちのびる (@omochi_otoca) <a href="https://twitter.com/omochi_otoca/status/1278998642897096706?ref_src=twsrc%5Etfw">July 3, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">15</div><div className="workinformation"><div className="worktitle">VRの最果て</div><div className="workname">さうす</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='VRの最果て'
                                creator='さうす'
                                number='15'
                                backgroundimage={pic15}
                                worldurl='https://vrchat.com/home/world/wrld_bfd429b5-c244-4b13-bba1-130b3329d480'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='存在しないと存在しない。存在しているのは存在しているから。ということを突き詰めたミニマルな作品。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='何もない空間は現実でも虚無の空間は変わらず圧迫感がある。特にバーチャルだと黒は空間ではなく壁に感じることがあり、圧迫感が凄い。'
                                class3="commentbox"
                                commentator3='sabakichi'
                                photo3={picSabakichi}
                                commentatortwitttername3='@knsthtyk'
                                commentatortwittterurl3='https://twitter.com/knshtyk'
                                comment3='そこには虚無があった'
                                class4="commentbox"
                                commentator4='masanaga'
                                photo4={picMasanage}
                                commentatortwitttername4='@tasklong'
                                commentatortwittterurl4='https://twitter.com/tasklong'
                                comment4='他者は自分を映す鏡といはよくいうが、自分以外の誰かがいないと自分がその空間にいる事すら認識できないと言う事をより顕著に感じられる空間だった。'
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_bfd429b5-c244-4b13-bba1-130b3329d480" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br />ワールドから要素を極限まで取り払う時、<br />最後に残るのは重力から解放された漆黒の場。<br />Just Tiny World 4.06KBはVRの最果て。<br />ワールドに一切表現は無いゆえ、<br />プレイヤー達の営みと、自ら光彩を放つアバター表現だけが浮き彫りとなる。<br />技術的チャレンジの最軽量ワールドをご覧あれ <a href="https://t.co/MWJ00JDqc3">pic.twitter.com/MWJ00JDqc3</a></p>&mdash; さうす@求職中 (@south_ws) <a href="https://twitter.com/south_ws/status/1279292017097371648?ref_src=twsrc%5Etfw">July 4, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">16</div><div className="workinformation"><div className="worktitle">あらゆる「もの」に浮力を</div><div className="workname">FORNO</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='あらゆる「もの」に浮力を'
                                creator='FORNO'
                                number='16'
                                backgroundimage={pic16}
                                worldurl='https://vrchat.com/home/world/wrld_a8489757-69e8-4fba-88d4-6f4fe8550bae'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='ものが浮くシステムを導入したプール。なぜか自分が飛び込んだら他のボールたちが沈んでしまった（私は水の比重を変えてしまったのだろうか）。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='プールを使った浮力体験'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='自分自身が浮力を感じる感覚を得るのはなかなか難しいものの、浮かべて遊ぶと言う事が出来るのは楽しい。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_a8489757-69e8-4fba-88d4-6f4fe8550bae" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">作品: あらゆる「もの」に浮力を<br /><br />VRがたどり着く未踏の地には<br />- VRでしかできない極致<br />- 現実を完全に仮想化したリアリティ<br />の二つがあると思います<br /><br />このバーチャルの夏を生きたリアルにする<br />World: Realistic Buoyancy Pool<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><a href="https://twitter.com/hashtag/madeWithUdon?src=hash&amp;ref_src=twsrc%5Etfw">#madeWithUdon</a> <a href="https://twitter.com/hashtag/VRChat?src=hash&amp;ref_src=twsrc%5Etfw">#VRChat</a><a href="https://twitter.com/hashtag/VRChat%E3%83%AF%E3%83%BC%E3%83%AB%E3%83%89%E7%B4%B9%E4%BB%8B?src=hash&amp;ref_src=twsrc%5Etfw">#VRChatワールド紹介</a> <a href="https://t.co/eLoz40jwyI">pic.twitter.com/eLoz40jwyI</a></p>&mdash; フォルノ(FORNO)☀Vket5登録した (@forno_fornya) <a href="https://twitter.com/forno_fornya/status/1279298501352947712?ref_src=twsrc%5Etfw">July 4, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">17</div><div className="workinformation"><div className="worktitle">ARTIFICIAL TYPHOON</div><div className="workna"><div className="workname">新月 朔</div><div className="award">佳作</div></div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='ARTIFICIAL TYPHOON'
                                creator='新月 朔'
                                number='17'
                                backgroundimage={pic17}
                                worldurl='https://vrchat.com/home/world/wrld_37151b4b-fa77-4542-9951-b91e548d7a71'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='前回の宇宙エレベーターに続き表現が圧倒的クオリティ。とにかく雲が美しい。住みたい、ここで仕事をしたい。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='台風の中に飛び込む、ラピュタ的な冒険感を体験できる。シンプルに現実ではない世界にぶち込んでくれる気持ち良さがある。'
                                class3="commentbox"
                                commentator3='sabakichi'
                                photo3={picSabakichi}
                                commentatortwitttername3='@knsthtyk'
                                commentatortwittterurl3='https://twitter.com/knshtyk'
                                comment3='モバイル向けゲームなどで近年見られるボリュームレンダリング調の雲をVRで移動しながら眺める体験。ライド系のコンテンツとしては自由に周遊もできて楽しめた。'
                                class4="commentbox"
                                commentator4='masanaga'
                                photo4={picMasanage}
                                commentatortwitttername4='@tasklong'
                                commentatortwittterurl4='https://twitter.com/tasklong'
                                comment4='探索や発見が楽しくなるよう作られつつも、不完全な要素、不安や恐怖心をあおるような演出などが創作世界として完成されていて、身を委ねて世界を体験することが出来る。'
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_37151b4b-fa77-4542-9951-b91e548d7a71" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">【ARTIFICIAL TYPHOON】<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br />気象コントロールできる未来をイメージした作品。現実では足を踏み入れることのない台風の中を通過し、雲下の圧迫感から雲上の開放感を感じてもらえたら幸いです。隠しイベントもあるよ。VRAA01参加作品「SPACE ELEVATOR」の姉妹ワールドでもあります。<a href="https://twitter.com/hashtag/VRChat?src=hash&amp;ref_src=twsrc%5Etfw">#VRChat</a> <a href="https://t.co/PY0wNMDaJE">pic.twitter.com/PY0wNMDaJE</a></p>&mdash; 新月 朔/Lunar Eclipse (@Shiduki_Saku) <a href="https://twitter.com/Shiduki_Saku/status/1279321248665747457?ref_src=twsrc%5Etfw">July 4, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">18</div><div className="workinformation"><div className="worktitle">Ruins City</div><div className="workname">IspVitamin</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Ruins City'
                                creator='IspVitamin'
                                number='18'
                                backgroundimage={pic18}
                                worldurl='https://vrchat.com/home/world/wrld_b26cfe51-3ae0-4157-8820-ed49da1afef2'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='廃墟のなかを進むが、何か起きるわけでもない。何も起きないことが真の廃墟感につながっているかもしれない。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='荒廃した世界。現実ではなかなか行くことができない廃墟に気軽にいけるのは魅力だ。だからこそ、よりこの世界で何があったのかを知る要素があると良さそう。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='作品とは無関係ではあるものの荒廃した世界の出口の様にVRAAポータルが置かれている光景は偶然の産物とは言え一筋の光のように見えた。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_b26cfe51-3ae0-4157-8820-ed49da1afef2" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">かつて人々の営みに溢れていたこの街も&quot;&quot;崩壊&quot;&quot;が起こった今となって未開の地ってわけさ。このコンクリートジャングルがどのように切り開かれていくか、見物だな。 <a href="https://t.co/oBVtvEqN3Q">https://t.co/oBVtvEqN3Q</a>　<a href="https://twitter.com/hashtag/VRChat_world%E7%B4%B9%E4%BB%8B?src=hash&amp;ref_src=twsrc%5Etfw">#VRChat_world紹介</a> 　<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/RpgDApBYCH">pic.twitter.com/RpgDApBYCH</a></p>&mdash; IspVitamin@被甲連合隊長/3Dモデル制作依頼受付中/VM5ﾃﾞﾌｫﾙﾄｷｭｰﾌﾞ/仮鋼博参 (@IspVitamin) <a href="https://twitter.com/IspVitamin/status/1279326174682812416?ref_src=twsrc%5Etfw">July 4, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">19</div><div className="workinformation"><div className="worktitle">宴会場</div><div className="workname">なめ</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='宴会場'
                                creator='なめ'
                                number='19'
                                backgroundimage={pic19}
                                worldurl='https://vrchat.com/home/world/wrld_97b5e1cf-a998-4d79-86d3-b33421ff02ac'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='広々とした畳の宴会場。現実世界でこういう場所で飲み会をする若い人いなくなってるんじゃないかと思うので、こういう質の空間がVRで改めて求められることの不思議さを感じる。'
                                class2="commentbox"
                                commentator2='龍lilea'
                                photo2={picLilea}
                                commentatortwitttername2='@lileaLab'
                                commentatortwittterurl2='https://twitter.com/lileaLab'
                                comment2='バーチャル飲み会はこちらで！いつでもどこからでも集まって飲めちゃう宴会場。'
                                class3="commentbox"
                                commentator3='えこちん'
                                photo3={picEcochin}
                                commentatortwitttername3='@ecochin'
                                commentatortwittterurl3='https://twitter.com/ecochin'
                                comment3='旅館に行かずとも宴会気分が楽しめそう。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_97b5e1cf-a998-4d79-86d3-b33421ff02ac" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">【宴会場】VR飲み会にいかが？<br /><br />外飲みがしづらくなってる今、VRで飲み騒ごう！<br />好きなおつまみ、好きな飲み物用意して自分のペースで楽しめる！<br />予約は必要なし、寝ても大丈夫、VRだから三密しても関係ない！<br /><br />※飲みすぎ注意<a href="https://twitter.com/hashtag/VRChat?src=hash&amp;ref_src=twsrc%5Etfw">#VRChat</a><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/jDV0ZVSr6c">pic.twitter.com/jDV0ZVSr6c</a></p>&mdash; なめりん (@namerin_VR) <a href="https://twitter.com/namerin_VR/status/1279716483950469120?ref_src=twsrc%5Etfw">July 5, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">20</div><div className="workinformation"><div className="worktitle">VR study vol1</div><div className="workname">Daras</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='VR study vol1'
                                creator='Daras'
                                number='20'
                                backgroundimage={pic20}
                                worldurl='https://vrchat.com/home/world/wrld_3c154898-77fe-4153-ae5a-ce28cc75afc7'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='「バーチャル世界とはいえ現実で見知っているものがベースだと安心」という、何となく広まっている理解に対してトライしている点が共感できる。ただこのテーマに対しては、緻密な建築的操作よりもっと大胆な価値観のぶつけ方がありそうだと思った。'
                                class2="commentbox"
                                commentator2='龍lilea'
                                photo2={picLilea}
                                commentatortwitttername2='@lileaLab'
                                commentatortwittterurl2='https://twitter.com/lileaLab'
                                comment2='木、RC、鉄骨が融合した新しい建築。安らぎを覚える空間。'
                                class3="commentbox"
                                commentator3='えこちん'
                                photo3={picEcochin}
                                commentatortwitttername3='@ecochin'
                                commentatortwittterurl3='https://twitter.com/ecochin'
                                comment3='巨大な宇宙空間にある家。宇宙にも和室があることが新鮮で面白い。こんな場所で休んでみたいという拘りが垣間見える。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_3c154898-77fe-4153-ae5a-ce28cc75afc7" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br />「VR study vol1」<br />　VR空間における住空間とは何かを思考した住宅である。木、RC、鉄構造が合体して一つになっていることが特徴である。<br />　嘘とリアルの境目のデザインをこの住宅の中でスタディしており「現実では不可能だが親しめる」デザインを各部に盛り込んでいる。 <a href="https://t.co/717H3Nq2R3">pic.twitter.com/717H3Nq2R3</a></p>&mdash; Daras/何もできない系建築学生 (@Daras1206) <a href="https://twitter.com/Daras1206/status/1281168505652441088?ref_src=twsrc%5Etfw">July 9, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">21</div><div className="workinformation"><div className="worktitle">LIMBO</div><div className="workname">JOE_JANOME</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='LIMBO'
                                creator='JOE_JANOME'
                                number='21'
                                backgroundimage={pic21}
                                worldurl='https://vrchat.com/home/world/wrld_e04e3d55-463f-459c-b648-0c2041fd4923'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='ファンタジー世界のちょっと不気味な天国の入り口感を感じた。'
                                class2="commentbox"
                                commentator2='龍lilea'
                                photo2={picLilea}
                                commentatortwitttername2='@lileaLab'
                                commentatortwittterurl2='https://twitter.com/lileaLab'
                                comment2='荘厳な空間。ファンタジーなアバターで何者かになりきりたい。'
                                class3="commentbox"
                                commentator3='えこちん'
                                photo3={picEcochin}
                                commentatortwitttername3='@ecochin'
                                commentatortwittterurl3='https://twitter.com/ecochin'
                                comment3='ボスがいそうな空間。思わず雰囲気に浸ってしまう世界観がある。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_e04e3d55-463f-459c-b648-0c2041fd4923" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">【LIMBO】<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br /><br />洗礼を受けられなかった魂が行き着く場所「辺獄」をテーマに、VRC内で生きる「落とし子」たちが集まる場所として作成しました<br /><br />主だった設置物は自作してあります<br /><br />入った瞬間の雰囲気を重視していますので、まだの方は是非見に行ってみて下さいな <a href="https://t.co/s1rMPgqGtV">pic.twitter.com/s1rMPgqGtV</a></p>&mdash; JOE@電脳喫茶じゃのめ☕️🦎 (@Joe_Janome) <a href="https://twitter.com/Joe_Janome/status/1280414384628527105?ref_src=twsrc%5Etfw">July 7, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">22</div><div className="workinformation"><div className="worktitle">Tanabata lanterns  -千願万色-</div><div className="workname">Lin Velvet</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Tanabata lanterns  -千願万色-'
                                creator='Lin Velvet'
                                number='22'
                                backgroundimage={pic22}
                                worldurl='https://vrchat.com/home/world/wrld_715f9996-1bd5-4fe5-ac0d-d0ab8d392d5b'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='鳥居を変形したハープが思いもつかない発想でおもしろかった。'
                                class2="commentbox"
                                commentator2='龍lilea'
                                photo2={picLilea}
                                commentatortwitttername2='@lileaLab'
                                commentatortwittterurl2='https://twitter.com/lileaLab'
                                comment2='作品提出日が、7/7。美しい七夕ワールド。うっとりとする短冊ストリームへ思わず願いを乗せた。'
                                class3="commentbox"
                                commentator3='えこちん'
                                photo3={picEcochin}
                                commentatortwitttername3='@ecochin'
                                commentatortwittterurl3='https://twitter.com/ecochin'
                                comment3='バーチャル空間という儚さの中にある願いをこめた短冊はなんだか魅力的な切なさを感じる。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_715f9996-1bd5-4fe5-ac0d-d0ab8d392d5b" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br />Tanabata lanterns -千願万色-<br /><br />七夕をモチーフにした 星と短冊の煌めくワールド。笹の傍らに現れる短冊と、宙へ舞う短冊の演出は自作パーティクルによって表現しています。ゆるやかに流れる光を 時を忘れて眺めていられる、そんな空間を目指しました。 <a href="https://t.co/ZaI8N9jFo2">pic.twitter.com/ZaI8N9jFo2</a></p>&mdash; Lin (@LINK_tw_39) <a href="https://twitter.com/LINK_tw_39/status/1280394504915181568?ref_src=twsrc%5Etfw">July 7, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">23</div><div className="workinformation"><div className="worktitle">Paradise on earth【Erebos】</div><div className="workname">れみーと</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Paradise on earth【Erebos】'
                                creator='れみーと'
                                number='23'
                                backgroundimage={pic23}
                                worldurl='https://vrchat.com/home/world/wrld_918c94a7-396d-4ccd-a087-b0370a225f9c'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='神話サイバーパンク的世界観。さまざまな表現が重なり合って全体として混沌としている様子に、日本の神話や曼荼羅的なものを感じた。'
                                class2="commentbox"
                                commentator2='龍lilea'
                                photo2={picLilea}
                                commentatortwitttername2='@lileaLab'
                                commentatortwittterurl2='https://twitter.com/lileaLab'
                                comment2='近未来的和の空間。広大なエリア全てでコンセプトが統一されており、巨大なアートを身体で味わうかのような作品。'
                                class3="commentbox"
                                commentator3='えこちん'
                                photo3={picEcochin}
                                commentatortwitttername3='@ecochin'
                                commentatortwittterurl3='https://twitter.com/ecochin'
                                comment3='バーチャル空間に再定義された和風世界。鳥居という記号はどんな場所にあっても力を持っていそうでかっこいい。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_918c94a7-396d-4ccd-a087-b0370a225f9c" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br />Paradise on earth【Erebos】<br /><br />技術が発達した未来の世界、<br />電子化された神々を信仰する宗教都市を創造しました。<br /><br />ところどころに神話好きな人が「おっ」となるギミックを隠しています。<br />全体的に空間表現を意識して作りました。<br />どこを撮っても絵になる　そんなワールドです。 <a href="https://t.co/ayPGkjGRsC">pic.twitter.com/ayPGkjGRsC</a></p>&mdash; れみーと (@remiiiiiiit) <a href="https://twitter.com/remiiiiiiit/status/1281223181303451648?ref_src=twsrc%5Etfw">July 9, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">24</div><div className="workinformation"><div className="worktitle">製鉄所 SteelWorks</div><div className="workname">S_朝霧</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='製鉄所 SteelWorks'
                                creator='S_朝霧'
                                number='24'
                                backgroundimage={pic24}
                                worldurl='https://vrchat.com/home/world/wrld_75ce7e3f-1ec6-4695-be36-63cfaa1148e9'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='現実の製鉄所に行ったことのない私にとっては、ここで得た体験＝製鉄所のリアルになっている。おそらく非常に高い再現度だと思うが、いつか本物に行くときまで答えはわからない。'
                                class2="commentbox"
                                commentator2='龍lilea'
                                photo2={picLilea}
                                commentatortwitttername2='@lileaLab'
                                commentatortwittterurl2='https://twitter.com/lileaLab'
                                comment2='工場見学の新しい形としても可能性を感じる。バーチャルだからこそ体験が可能な、現場。鉄が流し込まれる瞬間は見入ってしまった。'
                                class3="commentbox"
                                commentator3='えこちん'
                                photo3={picEcochin}
                                commentatortwitttername3='@ecochin'
                                commentatortwittterurl3='https://twitter.com/ecochin'
                                comment3='日常体験することができない工場を体験できるワクワク感がすごい。細かな動きも再現されており、観察眼と作りこみが凄まじい。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_75ce7e3f-1ec6-4695-be36-63cfaa1148e9" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br />【製鉄所 SteelWorks】<br /><br />人類の活動を支える「鋼」。<br />その鋼を生み出す最先端の現場です。<br /><br />普通は訪れることができない迫力の空間を体験できます。<br />ともすれば非現実的にさえ感じるこんな空間が「この世界に実在し動いている」と考えると、より一層迫力を感じられるかと思います。 <a href="https://t.co/VhwmQs5B2t">pic.twitter.com/VhwmQs5B2t</a></p>&mdash; S_朝霧@VRC (@s_asagiri_v) <a href="https://twitter.com/s_asagiri_v/status/1281836776185671683?ref_src=twsrc%5Etfw">July 11, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">25</div><div className="workinformation"><div className="worktitle">はじまり</div><div className="workna"><div className="workname">三日坊主</div><div className="operate">運営作品</div></div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='はじまり'
                                creator='三日坊主'
                                number='25'
                                backgroundimage={pic25}
                                worldurl='https://vrchat.com/home/world/wrld_5890de6f-67b7-42cd-bec2-78af2c98b264'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='ある人間の脳があるツールと絡み合って現実に作り出した「自然」があり、別の人間がその中を自由に飛行する、ということが2020年には既に可能なんだと深く感じた。'
                                class2="commentbox"
                                commentator2='龍lilea'
                                photo2={picLilea}
                                commentatortwitttername2='@lileaLab'
                                commentatortwittterurl2='https://twitter.com/lileaLab'
                                comment2='手を伸ばして飛行する体験がこんなに気持いいものだとは。いつまでも飛んでいた。動線らしきものはなく自由に飛んでいた、つもりが導かれていたようで終着を想定された空間へ辿りついた時にはやられた。無言のストーリーがある。感動。'
                                class3="commentbox"
                                commentator3='えこちん'
                                photo3={picEcochin}
                                commentatortwitttername3='@ecochin'
                                commentatortwittterurl3='https://twitter.com/ecochin'
                                comment3='巨大な空間の中にいくつもの層をがあり、抜ける度に新しい風景を見せてくれて何度も圧倒される。上下左右無限に広がる不思議な空間をどこまでも飛行して旅する気持ち良さは、高い没入感を与えてくれる。'
                                class4="commentbox"
                                commentator4='sabakichi'
                                photo4={picSabakichi}
                                commentatortwitttername4='@knsthtyk'
                                commentatortwittterurl4='https://twitter.com/knshtyk'
                                comment4='世界全体に漂う独特の空気感、すべての要素・箇所において成長段階を思わせる曖昧なフィニッシュ、それらを自由に突っ切ることで得る意味の無さによってわずかに染み出す意図のようなものが、全体の未達さを含めて(意図せずか意図してかは不明だが)アート作品として成立をしている。現実には存在し得ないスケールを成立させているのは空間作品としては見事。'
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_5890de6f-67b7-42cd-bec2-78af2c98b264" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">「inorganic space」<br /><br />無機のFrontierへ✨<br /><br />↓起動リンク<a href="https://t.co/mCCUkYDXGH">https://t.co/mCCUkYDXGH</a>)<br /> <a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/EOycASdO6x">pic.twitter.com/EOycASdO6x</a></p>&mdash; 三日坊主✨ (@mikkabouzu777) <a href="https://twitter.com/mikkabouzu777/status/1282103810962280449?ref_src=twsrc%5Etfw">July 12, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">26</div><div className="workinformation"><div className="worktitle">スクワットジム</div><div className="workna"><div className="workname">リノール</div><div className="award">佳作</div></div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='スクワットジム'
                                creator='リノール'
                                number='26'
                                backgroundimage={pic26}
                                worldurl='https://vrchat.com/home/world/wrld_dd201c97-22b3-4e50-97f5-1759b9670db9'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='現実の身体運動をテーマにした唯一の作品。エンタメとしてちゃんと楽しく設計されていて、VRでごく自然にジムが成立している。'
                                class2="commentbox"
                                commentator2='龍lilea'
                                photo2={picLilea}
                                commentatortwitttername2='@lileaLab'
                                commentatortwittterurl2='https://twitter.com/lileaLab'
                                comment2='みんなで運動する面白さ。スクワットってこんな楽しいものだったっけ？競争してたら健康になっちゃうワールド。'
                                class3="commentbox"
                                commentator3='えこちん'
                                photo3={picEcochin}
                                commentatortwitttername3='@ecochin'
                                commentatortwittterurl3='https://twitter.com/ecochin'
                                comment3='スクワットというシンプルで体力の必要な運動がとても楽しくなる。演出がとても良く、達成感を感じることができる。'
                                class4="commentbox"
                                commentator4='sabakichi'
                                photo4={picSabakichi}
                                commentatortwitttername4='@knsthtyk'
                                commentatortwittterurl4='https://twitter.com/knshtyk'
                                comment4='コンテンツとしての完成度が高い。このまま単体アプリとしても成立する水準。VRChatという一種の社会を素地としてこれらが組み上がっているというのは驚異のバランス感覚。'
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_dd201c97-22b3-4e50-97f5-1759b9670db9" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> トレーニング室において、熱心に筋トレしているグループはお互いを励まし合う。バーチャル世界では、空間もまた人々を励ます。チームで共通の目標を定め、一体感と達成感を得る空間。共に運動するライブ感。運動不足という課題を解決する現代的な手段。<a href="https://t.co/VfcbbOzab9">https://t.co/VfcbbOzab9</a> <a href="https://t.co/fbauj99351">pic.twitter.com/fbauj99351</a></p>&mdash; リノール (@F_Linoal) <a href="https://twitter.com/F_Linoal/status/1282164984579907585?ref_src=twsrc%5Etfw">July 12, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">27</div><div className="workinformation"><div className="worktitle">Marshmallow Camp</div><div className="workname">ヨドコロちゃん</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Marshmallow Camp'
                                creator='ヨドコロちゃん'
                                number='27'
                                backgroundimage={pic27}
                                worldurl='https://vrchat.com/home/world/wrld_02a0cda8-9866-476f-96c2-756b2db39df1'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='焼きマシュマロおいしい。リアルの焼きマシュマロは食感が完全に変質する面白さがあるので、いつかバーチャル味覚・触覚が実装される日に思いを馳せる。'
                                class2="commentbox"
                                commentator2='龍lilea'
                                photo2={picLilea}
                                commentatortwitttername2='@lileaLab'
                                commentatortwittterurl2='https://twitter.com/lileaLab'
                                comment2='マシュマロ焼き放題。マシュマロ焼くのってなぜこんなに楽しいのか。みんなで焼こうみんなで食べよう。'
                                class3="commentbox"
                                commentator3='えこちん'
                                photo3={picEcochin}
                                commentatortwitttername3='@ecochin'
                                commentatortwittterurl3='https://twitter.com/ecochin'
                                comment3='マシュマロが焼けるまでの時間はとても愛おしく楽しい時間だ。こんなキャンプがしてみたい。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_02a0cda8-9866-476f-96c2-756b2db39df1" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">「Marshmallow camp」 - もうトリガーボタンで&quot;食べるフリ&quot;なんてしなくていいんだよ。これが本当の&quot;食べる&quot;VR！　たき火を囲んでおいしいマシュマロを焼いて、食べて、食べさせっこもしちゃおう。 <a href="https://t.co/scuLrlBYSX">https://t.co/scuLrlBYSX</a> <a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/x25DBeGQvG">pic.twitter.com/x25DBeGQvG</a></p>&mdash; ヨドコロちゃん (@Yodokor0) <a href="https://twitter.com/Yodokor0/status/1282197114546601987?ref_src=twsrc%5Etfw">July 12, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">28</div><div className="workinformation"><div className="worktitle">Virtual Luppet Cafe</div><div className="workname">おきゅたんbot/宝来すみれ</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Virtual Luppet Cafe'
                                creator='おきゅたんbot/宝来すみれ'
                                number='28'
                                backgroundimage={pic28}
                                worldurl='https://vrchat.com/home/world/wrld_9a647b54-8a08-4ccc-8923-6ab6733d9e6c'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='現実のカフェにVRから出勤するシステム。事業として実際の運営まで行われていた稀有な事例。現実成分があることでコロナ禍のあおりを受けたのがいろいろな意味で残念。'
                                class2="commentbox"
                                commentator2='龍lilea'
                                photo2={picLilea}
                                commentatortwitttername2='@lileaLab'
                                commentatortwittterurl2='https://twitter.com/lileaLab'
                                comment2='番組制作の裏側を見れてしまうワールド。配信を想定し作られたギミックや配慮の数々が面白い。'
                                class3="commentbox"
                                commentator3='えこちん'
                                photo3={picEcochin}
                                commentatortwitttername3='@ecochin'
                                commentatortwittterurl3='https://twitter.com/ecochin'
                                comment3='バーチャルとリアルを繋ぐ新しい試みのBar。バーチャルの店自体も居心地がよく、会話も捗りそう。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_9a647b54-8a08-4ccc-8923-6ab6733d9e6c" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br />「Virtual Luppet Cafe」<br /><br />バーチャル世界から現実の秋葉原でお仕事！ <a href="https://twitter.com/hashtag/LuppetCafe?src=hash&amp;ref_src=twsrc%5Etfw">#LuppetCafe</a> 用の出勤ワールドです。正式オープンがCOVID-19の時期と重なり、今はもう現実で行くことができないけれど、記憶の場所にバーチャルで🍸✨<br /><br />わたしの体験談はYouTubeで▶️ <a href="https://t.co/5DtnC4FgAL">https://t.co/5DtnC4FgAL</a> <a href="https://t.co/Vi3B8Bcd6Z">pic.twitter.com/Vi3B8Bcd6Z</a></p>&mdash; おきゅたんbot☆宝来すみれ/VTuber (@OculusTan) <a href="https://twitter.com/OculusTan/status/1282558877741928448?ref_src=twsrc%5Etfw">July 13, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">29</div><div className="workinformation"><div className="worktitle">Air Flow Cyclone / CFD on VRChat</div><div className="workna"><div className="workname">邪気眼</div><div className="award">Finalist</div></div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Air Flow Cyclone / CFD on VRChat'
                                creator='邪気眼'
                                number='29'
                                backgroundimage={pic29}
                                worldurl='https://vrchat.com/home/world/wrld_0ab94949-b47c-49dd-8408-2e629283e6c3'
                                class="commentbox"
                                commentator='藤井直敬'
                                photo={pic_fujii}
                                commentatortwitttername='@NaotakaFujii'
                                commentatortwittterurl='https://twitter.com/NaotakaFujii'
                                comment='シンプルかつ美しい。さらに楽しい。'
                                class2="commentbox"
                                commentator2='吉田修平'
                                photo2={pic_yoshida}
                                commentatortwitttername2='@yosp'
                                commentatortwittterurl2='https://twitter.com/yosp'
                                comment2='シンプルで美しいデモでした。　知育玩具になりそう。'
                                class3="commentbox"
                                commentator3='豊田啓介'
                                photo3={pic_toyoda}
                                commentatortwitttername3='@toyoda_noiz'
                                commentatortwittterurl3='https://twitter.com/toyoda_noiz'
                                comment3='本来は高次元空間の理解であるはずのものが表現や伝達手段の技術的制約で2次元（＋時間）の表現にならざるを得なかった領域に、新しい体感的な理解とインタラクションを持ち込んでいるという点で非常に面白い。表現や体験として美しいのも良いし、これからの高次元グラフやダイアグラムはこういった没入型になっていくのではないかと感じさせる。'
                                class4="commentbox"
                                commentator4='落合陽一'
                                photo4={pic_ochiai}
                                commentatortwitttername4='@ochyai'
                                commentatortwittterurl4='https://twitter.com/ochyai'
                                comment4='タンジブルで楽しいデモだった．近くに寄ったり遠くから眺めたり．もう少し空間スケールを使えると面白いかもしれない．'
                                class5="commentbox"
                                commentator5='届木ウカ'
                                photo5={pic_uka}
                                commentatortwitttername5='@todoki_uka'
                                commentatortwittterurl5='https://twitter.com/todoki_uka'
                                comment5='多くの人がすぐに楽しさや面白さを感じられる作品でした。'
                                class6="commentbox"
                                commentator6='番匠カンナ'
                                photo6={picBanjo}
                                commentatortwitttername6='@banjokannna'
                                commentatortwittterurl6='https://twitter.com/Banjo_Kanna'
                                comment6='流体可視化の技術デモとして商業活用できそうだが、同時にとても美しいインタラクティブアートにもなっているという点がすごい。美しさにおいては個人的にNo.1だった。'
                                class7="commentbox"
                                commentator7='龍lilea'
                                photo7={picLilea}
                                commentatortwitttername7='@lileaLab'
                                commentatortwittterurl7='https://twitter.com/lileaLab'
                                comment7='CFDが体感出来てしまう空間。気流を意のままに操れる。2Dの画面で可視化されているのとは次元の違うわかりやすさと面白さがある。'
                                class8="commentbox"
                                commentator8='えこちん'
                                photo8={picEcochin}
                                commentatortwitttername8='@ecochin'
                                commentatortwittterurl8='https://twitter.com/ecochin'
                                comment8='技術単体でこれほど飽きずに見ていられる力がすさまじい。'
                                class9="commentbox"
                                commentator9='sabakichi'
                                photo9={picSabakichi}
                                commentatortwitttername9='@knshtyk'
                                commentatortwittterurl9='https://twitter.com/knshtyk'
                                comment9='単なる技術デモであっても、強度があるというだけでコンテンツ足り得るということを体現している。コンテンツとしてもう一歩先のレイヤーへと進めば間違いなく唯一となり得る可能性を持っていると感じた。'
                                /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_0ab94949-b47c-49dd-8408-2e629283e6c3" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">【Air Flow Cyclone】<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br /><br />「我々は、場の中に、生きている」<br />「三次元の流体シミュレーションをVR上で体感せよ！」<a href="https://t.co/mQv4MbgxsW">https://t.co/mQv4MbgxsW</a><br /><br />「「「　流　れ　を　体　感　せ　よ　」」」<a href="https://twitter.com/hashtag/CFD?src=hash&amp;ref_src=twsrc%5Etfw">#CFD</a> on <a href="https://twitter.com/hashtag/VRC?src=hash&amp;ref_src=twsrc%5Etfw">#VRC</a><br />※VRSSでクラッシュします<br />※Radeonで動かない可能性有<br />[BGM:ayato sound create] <a href="https://t.co/TYJ9ucHWgv">pic.twitter.com/TYJ9ucHWgv</a></p>&mdash; じゃき@邪気眼GPGPUptr (@konchannyan) <a href="https://twitter.com/konchannyan/status/1282735731413139456?ref_src=twsrc%5Etfw">July 13, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">30</div><div className="workinformation"><div className="worktitle">Green of Dark</div><div className="workname">Penguin3のお隣</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Green of Dark'
                                creator='Penguin3のお隣'
                                number='30'
                                backgroundimage={pic30}
                                worldurl='https://vrchat.com/home/world/wrld_a9a5aa57-6f43-4b5e-8347-699ffe60f1fc'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='VRChatで機能するモンハン的なゲームにトライしている。VRChatのようなVRプラットフォームがさまざまなゲーム制作プラットフォームになっていく未来は楽しそう。'
                                class2="commentbox"
                                commentator2='龍lilea'
                                photo2={picLilea}
                                commentatortwitttername2='@lileaLab'
                                commentatortwittterurl2='https://twitter.com/lileaLab'
                                comment2='VRChatでRPGが楽しめてしまうワールド。仲間を募って冒険したい。'
                                class3="commentbox"
                                commentator3='えこちん'
                                photo3={picEcochin}
                                commentatortwitttername3='@ecochin'
                                commentatortwittterurl3='https://twitter.com/ecochin'
                                comment3='いつもの自分のアバターでみんなと一緒に冒険を楽しめるのは至極嬉しい。大きなモンスターを目の前に狩りをするのはとても緊張感があり、VRと狩りの相性を感じることができた。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_a9a5aa57-6f43-4b5e-8347-699ffe60f1fc" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <br /><br />Green of Dark は、探索と戦闘がメインのワールドです。<br /><br />複数のランドマークをめぐり、森の動物や、モンスターたちを退け、森林最奥の遺跡を目指してください。<br /><br />本ワールドはVRAA02中はPrivate公開となります。<br />VRAA02後、再調整のうえでPublic公開致します。 <a href="https://t.co/hyN7EQVIsu">pic.twitter.com/hyN7EQVIsu</a></p>&mdash; Penguin3 (@Penguin3_vrc) <a href="https://twitter.com/Penguin3_vrc/status/1283056297424887815?ref_src=twsrc%5Etfw">July 14, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">31</div><div className="workinformation"><div className="worktitle">無演者ライブ</div><div className="workname">じぇしか</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='無演者ライブ'
                                creator='じぇしか'
                                number='31'
                                backgroundimage={pic31}
                                worldurl='https://vrchat.com/home/world/wrld_f9501b3e-4625-4ab8-acea-21fe0ad56b29'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='演者が楽器の生演奏をするのがこれまでのライブだとしたら、演者が生でないYoutube動画を流しつつも生で舞台に立つライブというのは不思議。新たなエンタメの可能性が開けそう。'
                                class2="commentbox"
                                commentator2='龍lilea'
                                photo2={picLilea}
                                commentatortwitttername2='@lileaLab'
                                commentatortwittterurl2='https://twitter.com/lileaLab'
                                comment2='音響効果のリアルなホール。スモークの演出もあり臨場感あるライブを体験出来る。好きな動画を再生できる仕組みも素晴らしく、ライブ非開催時でもお気に入りの映像を流せば音楽を全身で楽しめる。'
                                class3="commentbox"
                                commentator3='えこちん'
                                photo3={picEcochin}
                                commentatortwitttername3='@ecochin'
                                commentatortwittterurl3='https://twitter.com/ecochin'
                                comment3='バーチャルにいながらリアルな音響を楽しむことができる。みんなで集まり同時に体験する楽しさ・臨場感をより高めてくれる。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_f9501b3e-4625-4ab8-acea-21fe0ad56b29" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">作品名: 無演者ライブ<br /><br />▼ABOUT<br />ワールド『Jevenue LIVE』は、ライブハウス型の &quot;動画プレイヤー&quot; です。居場所や動作に応じて変化する音響、映像と同期する舞台照明など、動画から得られる体験を拡張してみました。「ライブを観に来たぞ！」という感覚を、楽しんでみてください。<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/THypGe9diK">pic.twitter.com/THypGe9diK</a></p>&mdash; じぇしか / Jessica (@jscmla1118) <a href="https://twitter.com/jscmla1118/status/1283015946613866498?ref_src=twsrc%5Etfw">July 14, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">32</div><div className="workinformation"><div className="worktitle">大空さんぽ道 -Parasol Promenade-</div><div className="workname">DONAMO-163</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='大空さんぽ道 -Parasol Promenade-'
                                creator='DONAMO-163'
                                number='32'
                                backgroundimage={pic32}
                                worldurl='https://vrchat.com/home/world/wrld_0664fc7a-29b4-4334-b7ef-559be5fcb7a0'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='パステルカラーのパラソルが織りなす夢のような空間。スタンダードシェーダーとは違うこういった方面の表現はまだまだ少ないのでもっと見てみたい。'
                                class2="commentbox"
                                commentator2='龍lilea'
                                photo2={picLilea}
                                commentatortwitttername2='@lileaLab'
                                commentatortwittterurl2='https://twitter.com/lileaLab'
                                comment2='パステルな色彩の美しい傘の世界。思わず飛び跳ねながら移動してしまう楽しさがある。'
                                class3="commentbox"
                                commentator3='えこちん'
                                photo3={picEcochin}
                                commentatortwitttername3='@ecochin'
                                commentatortwittterurl3='https://twitter.com/ecochin'
                                comment3='とても綺麗な色調の世界。リズミカルに配置された傘が気分をより高揚させてくれる。ゲーム的なワクワク感がある。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_0664fc7a-29b4-4334-b7ef-559be5fcb7a0" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">【大空さんぽ道 -Parasol Promenade-】<br /><br />雲を抜けた先にある、傘が生まれる場所。<br /><br />梅雨明けのような爽やかさと、空の広がりをバーチャルらしく表現してみました。<br /><br />パーティクルやシェーダー等を駆使して負荷を避けつつ、広い空間を演出しています。<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://twitter.com/hashtag/VRAA02?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02</a> <a href="https://t.co/wzYwbSnD5K">pic.twitter.com/wzYwbSnD5K</a></p>&mdash; ドナモ (@donamo163) <a href="https://twitter.com/donamo163/status/1283642775125164033?ref_src=twsrc%5Etfw">July 16, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">33</div><div className="workinformation"><div className="worktitle">TreeHouse: Nest</div><div className="workname">うどん猫</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='TreeHouse: Nest'
                                creator='うどん猫'
                                number='33'
                                backgroundimage={pic33}
                                worldurl='https://vrchat.com/home/world/wrld_67c41e99-616b-4e1b-af01-0b9c687cfc3d'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='とても豊かなツリーハウス。開口部のとり方やシークエンスなど、いわゆる現実の建築設計の巧みさが非常に高かった。'
                                class2="commentbox"
                                commentator2='龍lilea'
                                photo2={picLilea}
                                commentatortwitttername2='@lileaLab'
                                commentatortwittterurl2='https://twitter.com/lileaLab'
                                comment2='誰もが憧れる(に違いない)ツリーハウス。しかも空まで飛べてしまう。あがらないわけがない！夢が叶ったかのようなワールド。'
                                class3="commentbox"
                                commentator3='えこちん'
                                photo3={picEcochin}
                                commentatortwitttername3='@ecochin'
                                commentatortwittterurl3='https://twitter.com/ecochin'
                                comment3='一度は夢見るツリーハウス。余すところなく理想の全てが詰まったような作りが素晴らしい。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_67c41e99-616b-4e1b-af01-0b9c687cfc3d" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">【TreeHouse Nest】 <a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <br /><br />VR空間で安らげる場所として、自然に囲まれたツリーハウスのワールドを制作しました。<br />昼のワールドと夜のワールドがあるため、それぞれでまた違った雰囲気を楽しむことが出来ます。<br /><br />また、UDONを利用した飛行システムもご用意しました。 <a href="https://t.co/DXRTKVcXHy">pic.twitter.com/DXRTKVcXHy</a></p>&mdash; うどん猫 (@log_ever) <a href="https://twitter.com/log_ever/status/1283777131709820929?ref_src=twsrc%5Etfw">July 16, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">34</div><div className="workinformation"><div className="worktitle">あなたと私が生きる世界</div><div className="workname">がとーしょこら</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='あなたと私が生きる世界'
                                creator='がとーしょこら'
                                number='34'
                                backgroundimage={pic34}
                                worldurl='https://vrchat.com/home/world/wrld_7f19bebc-b8ee-4764-bc9f-c72011c78e36'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='ただこちらを見てくれるというだけで、ドキっとするくらいの存在感がある。"Live"を真正面から感じたので、個人的にはファイナリストに残したかった。ワールド内人数の「2」がにくい。'
                                class2="commentbox"
                                commentator2='龍lilea'
                                photo2={picLilea}
                                commentatortwitttername2='@lileaLab'
                                commentatortwittterurl2='https://twitter.com/lileaLab'
                                comment2='空間はそこに人がいることでより完成となるという事に気づかされる。そしてその人は必ずしもプレイヤーである必要はないという、新たな可能性を感じた。'
                                class3="commentbox"
                                commentator3='えこちん'
                                photo3={picEcochin}
                                commentatortwitttername3='@ecochin'
                                commentatortwittterurl3='https://twitter.com/ecochin'
                                comment3='こちらを見てくれていることで、自分という存在を認識してくれている錯覚をする。シンプルだが唯一無二のとても深い優しさを感じることができる。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_7f19bebc-b8ee-4764-bc9f-c72011c78e36" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">バーチャルな世界では様々な人が暮らしています。バーチャルな世界で生まれ、バーチャルな世界で生活している。そんな生命もあるかもしれません。そんなIFの始まりのワールドです。<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/xHcSAlbDhz">pic.twitter.com/xHcSAlbDhz</a></p>&mdash; がとーしょこら (@gatosyocora) <a href="https://twitter.com/gatosyocora/status/1283633061968490496?ref_src=twsrc%5Etfw">July 16, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">35</div><div className="workinformation"><div className="worktitle">パンザマスト</div><div className="workname">すだれ</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='パンザマスト'
                                creator='すだれ'
                                number='35'
                                backgroundimage={pic35}
                                worldurl='https://vrchat.com/home/world/wrld_d28e95fc-ed88-4b29-8dcd-6eca947e7c8c'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='夕暮れと聞き慣れた町内放送がつくる情景。日本人がみな共有する原風景だと思う。'
                                class2="commentbox"
                                commentator2='龍lilea'
                                photo2={picLilea}
                                commentatortwitttername2='@lileaLab'
                                commentatortwittterurl2='https://twitter.com/lileaLab'
                                comment2='終わった世界の夕暮れ。悲しみの中に懐かしさがある。しかし懐かしさを感じるからこそ、より深い悲しみを感じるのかもしれない。悲しい終わりというのは他人事ではなく日常の先にあり得ることを考えさせられた。'
                                class3="commentbox"
                                commentator3='えこちん'
                                photo3={picEcochin}
                                commentatortwitttername3='@ecochin'
                                commentatortwittterurl3='https://twitter.com/ecochin'
                                comment3='帰る家もないのに流れる放送が不気味であり、美しくもある。終末世界でも懐かしさを感じることができるのは不思議な感覚だ。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_d28e95fc-ed88-4b29-8dcd-6eca947e7c8c" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">「パンザマスト-PANZERMAST-」<br />パンザマストが鳴ったら家に帰ろう――。<br /><br />パンザマストが鳴り響く終末世界ワールド。どこか懐かしく、でも悲しい雰囲気を作ってみました。<br />『パンザマスト』とは千葉県柏市などの一部地域で『夕方五時のあの放送』を指し示します。<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><a href="https://twitter.com/hashtag/VRChat_world%E7%B4%B9%E4%BB%8B?src=hash&amp;ref_src=twsrc%5Etfw">#VRChat_world紹介</a> <a href="https://t.co/C6UmsLGRW4">pic.twitter.com/C6UmsLGRW4</a></p>&mdash; すだれ (@eradus222) <a href="https://twitter.com/eradus222/status/1284057110880202752?ref_src=twsrc%5Etfw">July 17, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">36</div><div className="workinformation"><div className="worktitle">最果ての繭</div><div className="workname">momoma</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='最果ての繭'
                                creator='momoma'
                                number='36'
                                backgroundimage={pic36}
                                worldurl='https://vrchat.com/home/world/wrld_dbc30ca3-61c0-4c32-9b69-f64c8d8d5980'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='過去を見る感覚。完全に記録された存在にインタラクションできないもどかしさ、つながりの希薄さがある。Portalで笑った。'
                                class2="commentbox"
                                commentator2='龍lilea'
                                photo2={picLilea}
                                commentatortwitttername2='@lileaLab'
                                commentatortwittterurl2='https://twitter.com/lileaLab'
                                comment2='これは少し未来の部屋探しの形としても価値がありそうな見せ方と感じた。空間のみでなくそこで過ごす様子もリアルに体験できる事で部屋の魅力がより身近に知ることが出来る。'
                                class3="commentbox"
                                commentator3='えこちん'
                                photo3={picEcochin}
                                commentatortwitttername3='@ecochin'
                                commentatortwittterurl3='https://twitter.com/ecochin'
                                comment3='滅びた後の世界で過去にあった生活の一部をホログラム見ているような感覚。実際に一緒にいるようで話しかけてしまった。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_dbc30ca3-61c0-4c32-9b69-f64c8d8d5980" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">コンパクトで落ち着いた空気のワールド&quot;The Farthest Cocoon&quot;を公開しました！<br />僕らがここで生きていることそのものをカタチにしようという試みです。よしなに遊んで、そして暮らしてください<br />※動画は外部から読み込んでいます<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://twitter.com/hashtag/VRChat?src=hash&amp;ref_src=twsrc%5Etfw">#VRChat</a> <a href="https://twitter.com/hashtag/VRChat_world%E7%B4%B9%E4%BB%8B?src=hash&amp;ref_src=twsrc%5Etfw">#VRChat_world紹介</a> <a href="https://t.co/lAOFMQ0Zod">pic.twitter.com/lAOFMQ0Zod</a></p>&mdash; momoma/ナル@VRChat (@momoma_creative) <a href="https://twitter.com/momoma_creative/status/1284054716217847808?ref_src=twsrc%5Etfw">July 17, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">37</div><div className="workinformation"><div className="worktitle">RoboconShowRoom</div><div className="workname">みゃおん</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='RoboconShowRoom'
                                creator='みゃおん'
                                number='37'
                                backgroundimage={pic37}
                                worldurl='https://vrchat.com/home/world/wrld_c3a4a304-80c6-48da-97a5-569b9d2ca2ec'
                                class="commentbox"
                                commentator='龍lilea'
                                photo={picLilea}
                                commentatortwitttername='@lileaLab'
                                commentatortwittterurl='https://twitter.com/lileaLab'
                                comment='ロボットはもうそれだけで魅力的で興奮してしまうが、それを間近で自由に見れてしまう。しかも動く。自分の手でも動かしたい欲が抑えられない。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='動きまわるロボット達を眺める、高専ロボコンの紹介的意味合いの場。普段は触ることができないロボットの操作ができたら楽しそう。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_c3a4a304-80c6-48da-97a5-569b9d2ca2ec" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">バーチャルロボット展示「RoboconShowRoom」をVRChat上にも再現しました！今年の高専ロボコンはオンライン開催となりましたが、ハードウェアのロボットをよりリアルに共有する選択肢のひとつとして、体感して頂けたら幸せます🍀<a href="https://twitter.com/hashtag/VRChat?src=hash&amp;ref_src=twsrc%5Etfw">#VRChat</a><a href="https://twitter.com/hashtag/VRChat_world%E7%B4%B9%E4%BB%8B?src=hash&amp;ref_src=twsrc%5Etfw">#VRChat_world紹介</a> <a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/p0tlmh3OpP">pic.twitter.com/p0tlmh3OpP</a></p>&mdash; みゃおん∞ (@blackcatyuma) <a href="https://twitter.com/blackcatyuma/status/1284385022179291136?ref_src=twsrc%5Etfw">July 18, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">38</div><div className="workinformation"><div className="worktitle">hope</div><div className="workna"><div className="workname">obake</div><div className="award">落合陽一賞</div></div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='hope'
                                creator='obake'
                                number='38'
                                backgroundimage={pic38}
                                worldurl='https://vrchat.com/home/world/wrld_5e91c31e-371a-4ed6-a3d7-a98374068ff3'
                                class="commentbox"
                                commentator='落合陽一'
                                photo={pic_ochiai}
                                commentatortwitttername='@ochyai'
                                commentatortwittterurl='https://twitter.com/ochyai'
                                comment='暗い部屋や長い洞穴を進みながら空間を味わっていく感覚や，音楽的に構成された空間の連続がインスタレーションとしての表現として心地よかった．物理空間への構成を考えると多大なコストがかかるところを，デジタルならではの表現として構成したことにより効果的な演出が可能になった．特に最初の部屋で長い時間を過ごすことにより，長い洞穴を歩くときの感覚が鋭敏になり，聴覚をすます感覚，映像を探す感覚も磨かれていた．コンテンツの内容自体はまだ作り込みがあるものの，空間インスタレーションとしての可能性を感じた．'
                                class2="commentbox"
                                commentator2='藤井直敬'
                                photo2={pic_fujii}
                                commentatortwitttername2='@NaotakaFujii'
                                commentatortwittterurl2='https://twitter.com/NaotakaFujii'
                                comment2='絶望の果ての希望？'
                                class3="commentbox"
                                commentator3='吉田修平'
                                photo3={pic_yoshida}
                                commentatortwitttername3='@yosp'
                                commentatortwittterurl3='https://twitter.com/yosp'
                                comment3='孤独感なのか？制作者のメッセージが気になったままです。'
                                class4="commentbox"
                                commentator4='豊田啓介'
                                photo4={pic_toyoda}
                                commentatortwitttername4='@toyoda_noiz'
                                commentatortwittterurl4='https://twitter.com/toyoda_noiz'
                                comment4='ストーリーと世界観を形と体験に落とし込むという作業を、このスケール感で実装しているチャレンジ精神に一票。全体を一つのインタラクティブな作品として考えた時に、個々の要素やそこでのインタラクションと、全体のストーリーや体験者の中に生じる世界観との間の因果関係のデザインはもっと構造化かつ洗練の可能性が大いにあるとも感じた。'
                                class5="commentbox"
                                commentator5='届木ウカ'
                                photo5={pic_uka}
                                commentatortwitttername5='@todoki_uka'
                                commentatortwittterurl5='https://twitter.com/todoki_uka'
                                comment5='1人用であることで、鑑賞者に与える情動が割増されていました。1人用なのに色んな人の反応を見たくなる作品でした。'
                                class6="commentbox"
                                commentator6='龍lilea'
                                photo6={picLilea}
                                commentatortwitttername6='@lileaLab'
                                commentatortwittterurl6='https://twitter.com/lileaLab'
                                comment6='世界観の素晴らしさはもちろんだが、ビジュアル面以外での作り込みもすごい。音、回数、時間、タイミング。先に進むにはいくつかの操作が必要だが、それらが全て巧みに計算されている。綿密に作り上げられた映画を体験しているかのような世界。'
                                class7="commentbox"
                                commentator7='えこちん'
                                photo7={picEcochin}
                                commentatortwitttername7='@ecochin'
                                commentatortwittterurl7='https://twitter.com/ecochin'
                                comment7='この世界の物語の主人公は自分なのだと思わせるほどの没入感がある心象風景。風景や物語の流れもシンプルだが、抑揚のある導線は最後に広がる風景をより美しくしてくれている。'
                                class8="commentbox"
                                commentator8='sabakichi'
                                photo8={picSabakichi}
                                commentatortwitttername8='@knshtyk'
                                commentatortwittterurl8='https://twitter.com/knshtyk'
                                comment8='良いサウンドスケープだった。それだけで価値があると思える。一本道で心象風景を表現する手法というのは古典的であり、点在する要素もそれ自体は特筆すべき目新しさはないのだが、バーチャル世界における音の重要性を非常に良く理解されており、その目敏さと作者の気丈な繊細さとが混ざり合い、非常に丁寧な(全くの暴力性を感じさせない)一種の高潔さを持った品質の高い音象空間とでも呼ぶべき場を形成している。私はずっとこういうものを待っている。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_5e91c31e-371a-4ed6-a3d7-a98374068ff3" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="en" dir="ltr">「hope」<br /><br />LaunchURL<a href="https://t.co/RxLD78EZFh">https://t.co/RxLD78EZFh</a><a href="https://twitter.com/hashtag/VRAA02?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02</a> <a href="https://t.co/eWfXD72N0Y">pic.twitter.com/eWfXD72N0Y</a></p>&mdash; 👻 (@gst_kkgr) <a href="https://twitter.com/gst_kkgr/status/1284446320732733444?ref_src=twsrc%5Etfw">July 18, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">39</div><div className="workinformation"><div className="worktitle">Live in the Frame</div><div className="workna"><div className="workname">Leiria</div><div className="award">Finalist</div></div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Live in the Frame'
                                creator='Leiria'
                                number='39'
                                backgroundimage={pic39}
                                worldurl='https://vrchat.com/home/world/wrld_e1f619ad-e20b-4d75-b160-0f9132d47fda'
                                class="commentbox"
                                commentator='藤井直敬'
                                photo={pic_fujii}
                                commentatortwitttername='@NaotakaFujii'
                                commentatortwittterurl='https://twitter.com/NaotakaFujii'
                                comment='独特の空気感が面白い。'
                                class2="commentbox"
                                commentator2='吉田修平'
                                photo2={pic_yoshida}
                                commentatortwitttername2='@yosp'
                                commentatortwittterurl2='https://twitter.com/yosp'
                                comment2='コミュニケーション出来ないもどかしさが狙いの作品ですね。'
                                class3="commentbox"
                                commentator3='豊田啓介'
                                photo3={pic_toyoda}
                                commentatortwitttername3='@toyoda_noiz'
                                commentatortwittterurl3='https://twitter.com/toyoda_noiz'
                                comment3='多重世界が同じ空間に共存し得るというVR空間ならではの体験の可視化としてとても面白い。道具立てにもう少し複数のチャンネルやその組み合わせの編集性などがあるとより面白くなると感じた。並行世界を空間次元で飛び越えるだけでなく、時間的にずれる、スケールがずれるなどの可能性も見てみたい。'
                                class4="commentbox"
                                commentator4='落合陽一'
                                photo4={pic_ochiai}
                                commentatortwitttername4='@ochyai'
                                commentatortwittterurl4='https://twitter.com/ochyai'
                                comment4='空間の広さが心地よい．どこかもどかしいところもねらいか．'
                                class5="commentbox"
                                commentator5='届木ウカ'
                                photo5={pic_uka}
                                commentatortwitttername5='@todoki_uka'
                                commentatortwittterurl5='https://twitter.com/todoki_uka'
                                comment5='同じ景色を見ているのに、相手と話すことも触れることも出来ずすぐにフレームの外に出て見えなくなってしまうのがもどかしかったです。1人で歩くよりも寂しかった。'
                                class6="commentbox"
                                commentator6='龍lilea'
                                photo6={picLilea}
                                commentatortwitttername6='@lileaLab'
                                commentatortwittterurl6='https://twitter.com/lileaLab'
                                comment6='すぐ近くにいるはずなのに、君は今どこにいるの？という感覚に度々おそわれるのは新鮮な体験。見えない孤独を感じる分、見えている時に安堵を覚える。相手がそこにいる事に喜びを感じるという素晴らしい仕掛け。'
                                class7="commentbox"
                                commentator7='えこちん'
                                photo7={picEcochin}
                                commentatortwitttername7='@ecochin'
                                commentatortwittterurl7='https://twitter.com/ecochin'
                                comment7='制限のある中で行われるコミュニケーションは、普段よりも注意して相手を見てしまう。'
                                class8="commentbox"
                                commentator8='sabakichi'
                                photo8={picSabakichi}
                                commentatortwitttername8='@knshtyk'
                                commentatortwittterurl8='https://twitter.com/knshtyk'
                                comment8='まさにバーチャルコミュニケーション。相手を見たいと思う気持ちを表出させる仕掛け。コミュニケーションの一方向性を可視化させたという点、不可思議さにおいて唯一無二の体験。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_e1f619ad-e20b-4d75-b160-0f9132d47fda" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">仮想世界では様々な存在が日々、一瞬一瞬に生きて暮らしています。そんな一瞬を切り取とった写真や絵の中の存在もまた生きているのだろうか？そんな着想から生まれたワールドです。あなたの隣にいる存在は今、生きていますか？そしてあなた自身も… <a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/suri2W3bbL">https://t.co/suri2W3bbL</a> <a href="https://t.co/2irIP1uehZ">pic.twitter.com/2irIP1uehZ</a></p>&mdash; レイリア (@leiria_vr) <a href="https://twitter.com/leiria_vr/status/1284459935963557890?ref_src=twsrc%5Etfw">July 18, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">40</div><div className="workinformation"><div className="worktitle">Isolated space</div><div className="workname">Asuka_x</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Isolated space'
                                creator='Asuka_x'
                                number='40'
                                backgroundimage={pic40}
                                worldurl='https://vrchat.com/home/world/wrld_d4234da0-dc1e-4286-92b2-ffb9b111389e'
                                class="commentbox"
                                commentator='龍lilea'
                                photo={picLilea}
                                commentatortwitttername='@lileaLab'
                                commentatortwittterurl='https://twitter.com/lileaLab'
                                comment='リラックスして過ごしたい時に訪れたい空間。ほどよく離れた島で、他の誰かにじゃまされない優雅な自分時間を過ごそう。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='海上にある個室。巨大な建築物を眺めながらゆったりと過ごす生活は特別感を感じる。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_d4234da0-dc1e-4286-92b2-ffb9b111389e" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br />「Isolated space」<br /><br />孤独な家と大きな壁のあるワールドです。<br />仮想空間の広さを強く意識できるように作成しました。<br /><br />見上げた先は果てしない。<br />突き放されたこの場所で、何に思いを馳せるのだろう。 <a href="https://t.co/iS6eIx1a01">pic.twitter.com/iS6eIx1a01</a></p>&mdash; Asuka_x (@Asuka45321052) <a href="https://twitter.com/Asuka45321052/status/1284468733755273217?ref_src=twsrc%5Etfw">July 18, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">41</div><div className="workinformation"><div className="worktitle">梅雨の雨上がり</div><div className="workname">あーるまーる</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='梅雨の雨上がり'
                                creator='あーるまーる'
                                number='41'
                                backgroundimage={pic41}
                                worldurl='https://vrchat.com/home/world/wrld_5d5a7811-079e-40dd-85d7-cfa5bde428c0'
                                class="commentbox"
                                commentator='龍lilea'
                                photo={picLilea}
                                commentatortwitttername='@lileaLab'
                                commentatortwittterurl='https://twitter.com/lileaLab'
                                comment='如雨露で水をあげていたら虹が出た。そんなちょっとしたギミックが楽しい。傘を持ち歩くという行為はそれ自体とてもシンプルであるのもかかわらず、紫陽花咲く庭というこの空間によってとてもステキな体験に昇華していた。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='梅雨の雨上がりにふりそそぐ太陽光を感じることができてとても気持ちが良い。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_5d5a7811-079e-40dd-85d7-cfa5bde428c0" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br /><br />【温室庭園Alma_Rose -After the Rain-】<br /><br />2020年の梅雨に公開していた温室庭園を<br />VRAA02参加のため別ワールドとして公開しました。<br />梅雨時期の雨上がりの空気感をイメージして制作しております。<br /><br />静かでゆったりとした時間を、お楽しみください。 <a href="https://t.co/X04kXHdCgz">pic.twitter.com/X04kXHdCgz</a></p>&mdash; あーるまーる🐭Earl-Marl (@512Air) <a href="https://twitter.com/512Air/status/1284499657167519746?ref_src=twsrc%5Etfw">July 18, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">42</div><div className="workinformation"><div className="worktitle">Choice</div><div className="workname">wata_pj</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Choice'
                                creator='wata_pj'
                                number='42'
                                backgroundimage={pic42}
                                worldurl='https://vrchat.com/home/world/wrld_684274cd-d8c8-4056-b98b-d86fc65daa84'
                                class="commentbox"
                                commentator='龍lilea'
                                photo={picLilea}
                                commentatortwitttername='@lileaLab'
                                commentatortwittterurl='https://twitter.com/lileaLab'
                                comment='自らの選択により世界の破壊が進む。一見選択次第であるようで、破壊は免れない。破壊を止めたい、回復させたい意志とは裏腹に破壊を進めてしまう哀しみと無力さを味わった。バグる世界の表現も秀逸。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='成功の道筋があるのかはわからないが、自分の選択によって導かれる緊張感は良かった。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_684274cd-d8c8-4056-b98b-d86fc65daa84" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br />『Choice』<br /><br />人生は選択の連続。あなたの選択によって世界は <a href="https://t.co/8efNmLmmbO">pic.twitter.com/8efNmLmmbO</a></p>&mdash; わた (@wata_pj) <a href="https://twitter.com/wata_pj/status/1284484155846217728?ref_src=twsrc%5Etfw">July 18, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">43</div><div className="workinformation"><div className="worktitle">Unlimited Elevator</div><div className="workname">セフィ</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Unlimited Elevator'
                                creator='セフィ'
                                number='43'
                                backgroundimage={pic43}
                                worldurl='https://vrchat.com/home/world/wrld_e02e8219-4cf5-4f4c-af06-3254ba78a66d'
                                class="commentbox"
                                commentator='龍lilea'
                                photo={picLilea}
                                commentatortwitttername='@lileaLab'
                                commentatortwittterurl='https://twitter.com/lileaLab'
                                comment='「そういう無駄もLiveのうち」とあるように、一見無駄と思える旧来の手段には不思議と魅力を感じる。そんな魅力を細部まで作り込まれた世界で味わえるワールド。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='クラシックなエレベーターに乗ることができるのは心が躍る。ボタンやギミックも現実に近いものであればより魅力的。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_e02e8219-4cf5-4f4c-af06-3254ba78a66d" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">【Unlimited Elevator】 <a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br /><br />古いエレベーターが動くだけのワールドです<br />特に何も起こりませんし、隠し要素もありません<br /><br />VRならテレポートすればすぐ移動できるのでエレベーターなんて無駄の極みですが、いいじゃないですか　そういう無駄もLiveのうちに入れてやってください <a href="https://t.co/odSI83IOUL">pic.twitter.com/odSI83IOUL</a></p>&mdash; セフィ (@Sephir_tree) <a href="https://twitter.com/Sephir_tree/status/1284686492976046080?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">44</div><div className="workinformation"><div className="worktitle">Living will β music -heart beat-</div><div className="workname">もやしの</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Living will β music -heart beat-'
                                creator='もやしの'
                                number='44'
                                backgroundimage={pic44}
                                worldurl='https://vrchat.com/home/world/wrld_054be682-4ec1-4fcc-98ca-4a808d4ab321'
                                class="commentbox"
                                commentator='龍lilea'
                                photo={picLilea}
                                commentatortwitttername='@lileaLab'
                                commentatortwittterurl='https://twitter.com/lileaLab'
                                comment='空間が音楽の魅力を高めている。始まりの階段は、言わばイントロ？ミュージックビデオの新しい形を感じた。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='作者の精神に入り込んでいくかのような世界があり、優しく触れたくなるような印象だ。もっと近づいたら戻れなくなるほどの世界が広がっていてもよさそう。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_054be682-4ec1-4fcc-98ca-4a808d4ab321" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br />【Living will β music -heart beat-】<br /><br />「紡いだ日々が寂しさのままに終わることのないように」 <a href="https://t.co/YLYUn50MAu">pic.twitter.com/YLYUn50MAu</a></p>&mdash; もやしの/靄篠@V随筆家 (@moyashino_VR) <a href="https://twitter.com/moyashino_VR/status/1284542704232226817?ref_src=twsrc%5Etfw">July 18, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">45</div><div className="workinformation"><div className="worktitle">August Nostalgia</div><div className="workname">まおー</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='August Nostalgia'
                                creator='まおー'
                                number='45'
                                backgroundimage={pic45}
                                worldurl='https://vrchat.com/home/world/wrld_19e46cfe-4ae3-40b0-be85-337783d86f81'
                                class="commentbox"
                                commentator='龍lilea'
                                photo={picLilea}
                                commentatortwitttername='@lileaLab'
                                commentatortwittterurl='https://twitter.com/lileaLab'
                                comment='懐かしさを覚える夏の田舎。家はただリアルに再現しているのではなく、ノブの高さやスケールがうまくVR用に最適化されている。空間を味わうだけでなくここで過ごすことも考えられた、工夫の行き届いた建築になっている。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='どこか懐かしい田舎の家のある環境。こんな所でみんなと遊んでみたかった！と思わせる要素がいっぱいで優しい気持ちになれる。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_19e46cfe-4ae3-40b0-be85-337783d86f81" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">[August Nostalgia]<br /><br />強い日差し、セミの鳴き声、風鈴の音…<br />体験したことが無くてもなぜか懐かしいと感じる、もう戻れないあの日常。<br />バーチャル空間でなら少しだけ戻れるかもしれません。<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/o5ZGsQ2Oh9">pic.twitter.com/o5ZGsQ2Oh9</a></p>&mdash; まおー (@tonomaoo) <a href="https://twitter.com/tonomaoo/status/1284642235976265729?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">46</div><div className="workinformation"><div className="worktitle">look</div><div className="workna"><div className="workname">円フツ</div><div className="award">佳作</div></div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='look'
                                creator='円フツ'
                                number='46'
                                backgroundimage={pic46}
                                worldurl='https://vrchat.com/home/world/wrld_a750293d-dcfd-418b-b4e5-e9bf57cbae6c'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='VRという「現実の身体が不自由な人でも自由に動ける世界」で、視線のみで行うコミュニケーション自体にどんな価値がありうるか。ゲームへの応用以上の何かまで到達する予感があるともっと良かった。'
                                class2="commentbox"
                                commentator2='龍lilea'
                                photo2={picLilea}
                                commentatortwitttername2='@lileaLab'
                                commentatortwittterurl2='https://twitter.com/lileaLab'
                                comment2='視線でインタラクトという新しさ。よく考えるとむしろ古いのかもしれないが、逆に新鮮さを覚えるという不思議。「あそこを見たら何が起きる？」夢中になってあちらこちら見回していた。'
                                class3="commentbox"
                                commentator3='えこちん'
                                photo3={picEcochin}
                                commentatortwitttername3='@ecochin'
                                commentatortwittterurl3='https://twitter.com/ecochin'
                                comment3='視線の先に起こる変化が新鮮でとても楽しい。最初から全て出してしまうのではなく、発見していく楽しみがもっと出てくると面白そう。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_a750293d-dcfd-418b-b4e5-e9bf57cbae6c" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">「look」<br />触れられないものに目で触れるワールドです。青いサークルが視点を表しています。<a href="https://t.co/KmxgiEDrf5">https://t.co/KmxgiEDrf5</a><br /> <a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/WPJd9fkcGm">pic.twitter.com/WPJd9fkcGm</a></p>&mdash; 円フツ (@enfutu) <a href="https://twitter.com/enfutu/status/1284722579442262016?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">47</div><div className="workinformation"><div className="worktitle">Tower defense live 1.0</div><div className="workna"><div className="workname">ソリ</div><div className="award">佳作</div></div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Tower defense live 1.0'
                                creator='ソリ'
                                number='47'
                                backgroundimage={pic47}
                                worldurl='https://vrchat.com/home/world/wrld_16c79658-6fda-4205-a579-26bfd20140cb'
                                class="commentbox"
                                commentator='龍lilea'
                                photo={picLilea}
                                commentatortwitttername='@lileaLab'
                                commentatortwittterurl='https://twitter.com/lileaLab'
                                comment='普通にバトル盛り上がった。面白い。その盤の上にも登る事も出来る点もまた面白く、観戦者側も実寸大で繰り広げるバトルフィールドの中へ入りこんで迫力ある戦いを楽しむことが出来るのは新しい体験であった。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='ゲームとしての完成度も高く、バトルを間近で見る事ができるのもVRならではで楽しい。'
                                class3="commentbox"
                                commentator3='sabakichi'
                                photo3={picSabakichi}
                                commentatortwitttername3='@knshtyk'
                                commentatortwittterurl3='https://twitter.com/knshtyk'
                                comment3='とてもよく出来ており、VRChatでここまで出来るのに驚く。マルチ対応のVRゲームがソーシャルVRプラットフォームに存在しているのも嬉しいだろう。バーチャル空間という特殊な場所で行う特有の楽しみや、この場所に存在していることで生まれるマルチVRゲームとは異なる一歩先の体験が生み出されていると、唯一無二のものとして輝くのではないかと感じた。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_16c79658-6fda-4205-a579-26bfd20140cb" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br />「Tower Defense Live 1.0」<br /><br />タワーディフェンス型のゲームワールドを作ってみました。2人用です。対戦の様子をみんなで観戦し、ライブ感覚で盛り上がれます<br /><br />初プレイのあめちゃんｖｓ余裕綽々なけぱちゃん戦の様子を録画してみました。予想外の結果で大盛り上がりでした！ <a href="https://t.co/Rl6Yv36lZk">pic.twitter.com/Rl6Yv36lZk</a></p>&mdash; ソリ (@SolihTia_xiv) <a href="https://twitter.com/SolihTia_xiv/status/1284720550686781442?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">48</div><div className="workinformation"><div className="worktitle">KURASHI home</div><div className="workname">XtransfeR (宮井智尋)</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='KURASHI home'
                                creator='XtransfeR (宮井智尋)'
                                number='48'
                                backgroundimage={pic48}
                                worldurl='https://vrchat.com/home/world/wrld_a11a607e-0b4e-43df-b2b2-9e1b66fd481b'
                                class="commentbox"
                                commentator='龍lilea'
                                photo={picLilea}
                                commentatortwitttername='@lileaLab'
                                commentatortwittterurl='https://twitter.com/lileaLab'
                                comment='一つのワールドで多様な世界観に触れられる、とても広大な街々。車が用意されているものの、うっかり欲に負け自由に駆け回ってしまった。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='街というくくりに世界を詰め込む事で、ビジュアル的な面白さだけが出てしまっている感じ。移動という現実でも退屈になりやすい要素は、より注意を払って楽しめるものにしなければいけないのかもしれない。'
                                class3="commentbox_invisible"
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_a11a607e-0b4e-43df-b2b2-9e1b66fd481b" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">【KURASHI Home】移動の価値を検証するワールド。<br />車に乗り、時代・文化・様式が異なる4つの都市を巡ることで理想の暮らしを探ります。ワープが可能なVRでも残る移動の楽しさとは何かを是非感じてみてください。<br />アイテムを集めると隠された場所が解放されます。<a href="https://twitter.com/hashtag/VRChat_world%E7%B4%B9%E4%BB%8B?src=hash&amp;ref_src=twsrc%5Etfw">#VRChat_world紹介</a><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/ASiAXiKfOM">pic.twitter.com/ASiAXiKfOM</a></p>&mdash; XtransfeR Project (@xtransfer3) <a href="https://twitter.com/xtransfer3/status/1284724610131345409?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">49</div><div className="workinformation"><div className="worktitle">VirtualComfortRoom:Edge</div><div className="workname">るら</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='VirtualComfortRoom:Edge'
                                creator='るら'
                                number='49'
                                backgroundimage={pic49}
                                worldurl='https://vrchat.com/home/world/wrld_fb5d7597-e909-403a-8a13-c61ee03111cc'
                                class="commentbox"
                                commentator='龍lilea'
                                photo={picLilea}
                                commentatortwitttername='@lileaLab'
                                commentatortwittterurl='https://twitter.com/lileaLab'
                                comment='とても丁寧に作り込まれた家。スクショを取ればそのままCGパースとして成り立ちそうなクオリティ。細部まで拘りを感じられる。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='誰でも簡単に高級住宅に住む事ができるのは、まさにVR世界の生活の理想の一つだ。考え抜かれた細部のデザインは目を見張るものがある。'
                                class3="commentbox_invisible"
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_fb5d7597-e909-403a-8a13-c61ee03111cc" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">VirtualComfortRoom:Edge<br /><br />VRでの快適な住空間を求め、心地良い時間を過ごすために製作した住宅ワールドです。<br />バーチャルならではの幻想的な空間で、快適な時間をお楽しみください。<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/OLxTYBUj8R">pic.twitter.com/OLxTYBUj8R</a></p>&mdash; るら／VRC (@Lu_Ra_999) <a href="https://twitter.com/Lu_Ra_999/status/1284732608790708224?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">50</div><div className="workinformation"><div className="worktitle">Questコロニー</div><div className="workname">Questコロニー</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Questコロニー'
                                creator='Questコロニー'
                                number='50'
                                backgroundimage={pic50}
                                worldurl='https://vrchat.com/home/world/wrld_d021c842-7d02-4d0a-ab9f-4dd6d24d19a4'
                                class="commentbox"
                                commentator='龍lilea'
                                photo={picLilea}
                                commentatortwitttername='@lileaLab'
                                commentatortwittterurl='https://twitter.com/lileaLab'
                                comment='いかに魅力的な空間であってもハードによるハードルはまだ現実的に残る中、Quest対応である事と、コミュニケーションをメインとした空間であることは多くの人へバーチャル空間への玄関口となっているように思う。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='Quest対応の交流ワールド。気軽にVR導入ができるQuestの交流場があることは、これから始める人にとって大きな意味を持つ。'
                                class3="commentbox_invisible"
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_d021c842-7d02-4d0a-ab9f-4dd6d24d19a4" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">Questユーザー向け交流所、Questコロニー<br />過去開催分の写真を展示したアーカイブワールドを公開しました<br />スタンドアローン型VRHMDによりVR世界の人のつながりはさらに広がっていきます<br />virtualの未来へ向かう、ここが&quot;最前線&quot;<br />Quest最適化のためのギミックも実装しています<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/QMcxz3cqPx">pic.twitter.com/QMcxz3cqPx</a></p>&mdash; Questコロニー【公式】 (@QuestColony) <a href="https://twitter.com/QuestColony/status/1284741095096631297?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">51</div><div className="workinformation"><div className="worktitle">FRAGILE SHOP FT․ NIRGE AVATARS</div><div className="workname">aku955</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='FRAGILE SHOP FT․ NIRGE AVATARS'
                                creator='aku955'
                                number='51'
                                backgroundimage={pic51}
                                worldurl='https://vrchat.com/home/world/wrld_5341ed2f-1160-4a64-a471-ebf5ee201db9'
                                class="commentbox"
                                commentator='龍lilea'
                                photo={picLilea}
                                commentatortwitttername='@lileaLab'
                                commentatortwittterurl='https://twitter.com/lileaLab'
                                comment='トゥーンなアクセサリーショップ。空間の調子とマッチした、細部のちょっとした動きの作り込みも心地よい。商品ではないものも欲しくなる。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='爽やかな色合いが気持ちいいお店。単なるアクセサリーショップにとどまらず、環境を含めたデザインが美しい。'
                                class3="commentbox_invisible"
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_5341ed2f-1160-4a64-a471-ebf5ee201db9" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">「FRAGILE SHOP FT․ NIRGE AVATARS」<br /><br />VRアバター用のアクセサリーショップです<br />アクセサリーの設置だけでなく、コンセプトに沿ったパブリックなアバターも用意しました<br /><br />店の外にはフリースローが楽しめるスペースもあります<br /><br />VR空間に住む人たちに、新しいお店のカタチを提案！<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/PV6wX8va7w">pic.twitter.com/PV6wX8va7w</a></p>&mdash; アク / aku955 (@AkunoVrc) <a href="https://twitter.com/AkunoVrc/status/1284750596998758400?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">52</div><div className="workinformation"><div className="worktitle">xRで試着可能な未来 with VR</div><div className="workname">さばちゃん</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='xRで試着可能な未来 with VR'
                                creator='さばちゃん'
                                number='52'
                                backgroundimage={pic52}
                                worldurl='https://vrchat.com/home/world/wrld_7d0c23f5-0d9d-49b0-bea0-649e13298a22'
                                class="commentbox"
                                commentator='龍lilea'
                                photo={picLilea}
                                commentatortwitttername='@lileaLab'
                                commentatortwittterurl='https://twitter.com/lileaLab'
                                comment='これまではアイテムの位置に自分を合わせるのが普通だった試着。ところがここではちゃんと試着が出来る。当たり前がついに実現していた。この仕組みは今後のスタンダードとなりそう。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='現実の店頭でするようなアクセサリーの試着に近く、ストレス無く遊ぶことができて楽しい。'
                                class3="commentbox_invisible"
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_7d0c23f5-0d9d-49b0-bea0-649e13298a22" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">作品タイトル xRで試着可能な未来 with VR<br />ワールド名 Let&#39;s Try On with Virtual!<br />xR技術を活用すれば場所や時間に悩まされず自由にファッションを楽しめる、そんな豊かな未来を想像して作成しました。現実みを意識したサイズ変更と直感的な試着が特徴です<a href="https://t.co/XopaBKwdfT">https://t.co/XopaBKwdfT</a><br /> <a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/ktDmho8HEX">pic.twitter.com/ktDmho8HEX</a></p>&mdash; さばちゃん (@sabachan_vrc) <a href="https://twitter.com/sabachan_vrc/status/1284778601724776449?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">53</div><div className="workinformation"><div className="worktitle">A Miniascape</div><div className="workna"><div className="workname">fotfla</div><div className="award">届木ウカ賞</div></div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='A Miniascape'
                                creator='fotfla'
                                number='53'
                                backgroundimage={pic53}
                                worldurl='https://vrchat.com/home/world/wrld_1d860a1f-93d2-428c-a329-05f3218697c7'
                                class="commentbox"
                                commentator='届木ウカ'
                                photo={pic_uka}
                                commentatortwitttername='@todoki_uka'
                                commentatortwittterurl='https://twitter.com/todoki_uka'
                                comment='「地形を生成してその地に立つ」というギミックのために様々な演出方法が考えられる中で、地球儀を回して生成する地形を確定させ、魔法陣を起動してワープする、という統一感のある儀礼を選択されていたので、抵抗なくワールドの世界観に入り込むことができました。'
                                class2="commentbox"
                                commentator2='吉田修平'
                                photo2={pic_yoshida}
                                commentatortwitttername2='@yosp'
                                commentatortwittterurl2='https://twitter.com/yosp'
                                comment2='お花畑が綺麗でした。'
                                class3="commentbox"
                                commentator3='豊田啓介'
                                photo3={pic_toyoda}
                                commentatortwitttername3='@toyoda_noiz'
                                commentatortwittterurl3='https://twitter.com/toyoda_noiz'
                                comment3='地形をその場で生成できるという面白さと、それに没入して体験できるという入れ子の内外の視点が一つのシステムで共存していて、昨年のVRAAでも同様の方向の作品はあったが表現としても体感としても一歩進んだ感じがした。神の視点と人の視点、虫の視点などの並行体験はVRならではの可能性。'
                                class4="commentbox"
                                commentator4='落合陽一'
                                photo4={pic_ochiai}
                                commentatortwitttername4='@ochyai'
                                commentatortwittterurl4='https://twitter.com/ochyai'
                                comment4='ワールドの切り替わりが心地よい.VRインスタレーションならでは．'
                                class5="commentbox"
                                commentator5='藤井直敬'
                                photo5={pic_fujii}
                                commentatortwitttername5='@NaotakaFujii'
                                commentatortwittterurl5='https://twitter.com/NaotakaFujii'
                                comment5='新しい地形を行き来するシンプルな楽しさに驚いた。'
                                class6="commentbox"
                                commentator6='龍lilea'
                                photo6={picLilea}
                                commentatortwitttername6='@lileaLab'
                                commentatortwittterurl6='https://twitter.com/lileaLab'
                                comment6='その日の気分で空間も変えられるシステムはまさに「Live」への解であるように感じた。空間は固定されたものではなくユーザーが自由に創れる、選べるという柔軟さは面白さのみならず、人が過ごすこれからの空間の有り様として必要なものでもあるように思う。'
                                class7="commentbox"
                                commentator7='えこちん'
                                photo7={picEcochin}
                                commentatortwitttername7='@ecochin'
                                commentatortwittterurl7='https://twitter.com/ecochin'
                                comment7='製作者が見てほしい風景を縛るのではなく、遊ぶ人が探し選ぶ自由を持つことができる。自分だけのエモい風景を見つけ出す行為そのものもエモい。'
                                class8="commentbox"
                                commentator8='sabakichi'
                                photo8={picSabakichi}
                                commentatortwitttername8='@knshtyk'
                                commentatortwittterurl8='https://twitter.com/knshtyk'
                                comment8='ランダムなジオメトリを生成する仕組みは古今東西あれど、意外にもVR空間としてランドスケープが無限に生成されるのをVR空間内で行えるというコンテンツはなかったのではないか。箱庭という呼称はあくまで俯瞰視点によるものだ。しかし、ここで生成された空間は箱庭になぞらえてはいるが、真に場所が現出している点がユニーク。構造の設計次第で受け取り方をコントロールできるお手本のようなワールドであると感じた。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_1d860a1f-93d2-428c-a329-05f3218697c7" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">Title : A Miniascape<br /><br />1から∞の風景を<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/fZX67aDmWF">pic.twitter.com/fZX67aDmWF</a></p>&mdash; fotfla (@fotfla) <a href="https://twitter.com/fotfla/status/1284790871200657408?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">54</div><div className="workinformation"><div className="worktitle">VRCクイズワールド</div><div className="workname">犬の会長</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='VRCクイズワールド'
                                creator='犬の会長'
                                number='54'
                                backgroundimage={pic54}
                                worldurl='https://vrchat.com/home/world/wrld_5f33215b-4b35-4aaf-b66a-ad5e81aa6f6a'
                                class="commentbox"
                                commentator='龍lilea'
                                photo={picLilea}
                                commentatortwitttername='@lileaLab'
                                commentatortwittterurl='https://twitter.com/lileaLab'
                                comment='クイズ番組が作れるという画期的ワールド。作りも本格的。リアルではそうそう用意に作れるようなシステムではないが、バーチャルではこれが実現できてしまっている事に驚きを覚える。そしてリアルに引けを取らないどころかより多様な拡がりの可能性を持っている。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='クイズ番組として使用されて初めてこの作品の面白さが出るのかもしれない。'
                                class3="commentbox_invisible"
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_5f33215b-4b35-4aaf-b66a-ad5e81aa6f6a" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br /><br />「VRCクイズワールド」<br /><br />VRAA02参加作品として、配信に不慣れな方も手軽にクイズ番組の配信が行える汎用型放送室を製作させて頂きました。<br /><br />勿論、普通のクイズワールドとして遊ぶ事も可能です。是非御活用下さい(｀･ω･´)ゞ <a href="https://t.co/TBltF1EbaN">pic.twitter.com/TBltF1EbaN</a></p>&mdash; 犬の会長 (@inunokaicyou) <a href="https://twitter.com/inunokaicyou/status/1284790605688631302?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">55</div><div className="workinformation"><div className="worktitle">跡。/ato</div><div className="workname">mitsuba217</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='跡。/ato'
                                creator='mitsuba217'
                                number='55'
                                backgroundimage={pic55}
                                worldurl='https://vrchat.com/home/world/wrld_f99c13f1-8dc7-43e7-b06e-6048ea00023d'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='ギミック自体を発見した時の面白さはある。ただそれ以上にインパクトがなく、惜しい。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='バスのガラス窓をこすって不特定の相手とコミュニケーションするあの感じ。「いる」を「いた」に読み替えたのは面白い。実装がもう少し精緻であれば、存在の解像度も上がりそう。'
                                class3="commentbox_invisible"
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_f99c13f1-8dc7-43e7-b06e-6048ea00023d" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">「跡。/ato」<br />踏んだ跡や触れた跡。行動の後に残るものが生きた証であり、そのお方の景色なのではないかと。<br />拙い表現で恐縮ですが、そんな思いを込めました。<br />部屋の床・壁・天井に触れた部分から外の景色が見えます。<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/EUjxTT3IqF">pic.twitter.com/EUjxTT3IqF</a></p>&mdash; mitsuba217 (@_mitsuba217) <a href="https://twitter.com/_mitsuba217/status/1284792867240591360?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">56</div><div className="workinformation"><div className="worktitle">Glamogeous Mansion</div><div className="workname">Musha</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Glamogeous Mansion'
                                creator='Musha'
                                number='56'
                                backgroundimage={pic56}
                                worldurl='https://vrchat.com/home/world/wrld_9520754c-c84c-4afd-a273-ed11663db547'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='少しずつ不可思議な部屋になっていくホラー。好奇心をくすぐる作りが気持ち良い。ストーリーがもっと色濃く出てもよさそう。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='VRで一度見てみたかった感じのジオメトリがありおもしろい。ストーリーの残滓を追いかけるような空間づくりがされていて、この贅沢な資源の使い方はVRでしか味わえないなと思う。'
                                class3="commentbox_invisible"
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_9520754c-c84c-4afd-a273-ed11663db547" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br />【Glamogeous Mansion】<br />16世紀、大富豪のロベルト・ムシャローダ公爵３世は失踪が相次ぐ曰く付きの沼地に館を建設する。<br />だがその後、彼を見たものはいない。<br /><br />我々はこの謎を解き明かして彼を見つけ出してくれる勇敢な研究者を常時募集している。<br />※ホラーワールドではありません。 <a href="https://t.co/9ZaD4oGFKU">pic.twitter.com/9ZaD4oGFKU</a></p>&mdash; Musha ムーシャ (@Musha_JP) <a href="https://twitter.com/Musha_JP/status/1284795378034851841?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">57</div><div className="workinformation"><div className="worktitle">これは後の世界</div><div className="workname">ハツェ</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='これは後の世界'
                                creator='ハツェ'
                                number='57'
                                backgroundimage={pic57}
                                worldurl='https://vrchat.com/home/world/wrld_f9e35506-bbc2-4364-bea6-981507e447b0'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='VRにおける見ている世界の違いというものにスポットを当てていてわかりやすい。神社というパワースポットの成せる業のようにも思わせてくれる作りがシンプルに良い。もっと違いが出てしまっても良い気がする。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='時が止まる体験、異なる世界を観測している認識のズレというVRに期待する体験が込められていてシンプルな現象自体のおもしろさがある。神社の静寂さを再解釈して構築し直しているのが少し奇妙な感覚がある。導入がしっかりしている細やかさと気遣いに良心を感じる。'
                                class3="commentbox_invisible"
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_f9e35506-bbc2-4364-bea6-981507e447b0" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">(HC ==&gt; everyone)<br /><br />「えっ、止まってるって言ってるじゃん」<br />「いや、何を言ってるのかがさっぱり...」<br />「文字通りだって」<br />「そんなこと言われても特に変わりはないし...」<br />「とりあえず、来てみなって...！」<br />「おぉっ...？」<br /><br />ワールド「On Instances.」<br />あなた自身の目で是非。<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/mDnzmgiOzI">pic.twitter.com/mDnzmgiOzI</a></p>&mdash; ハツェ (@kohu_vr) <a href="https://twitter.com/kohu_vr/status/1284804267383984129?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">58</div><div className="workinformation"><div className="worktitle">光と音の重なり合い</div><div className="workname">hattori</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='光と音の重なり合い'
                                creator='hattori'
                                number='58'
                                backgroundimage={pic58}
                                worldurl='https://vrchat.com/home/world/wrld_21cf2af1-be3b-4d9b-9442-3b44079afe0c'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='シンプルだがとても美しい。動的に変わるライティングが様々な感情を部屋に映し出している。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='一見シンプルだが、極めて繊細な感性の歓びのために造られたワールド。コンピュータの色深度に限界を依存しつつも、たったこれだけの中に美しさを見出すことができることに気が付かされる。現象を独立したかたちで存在させやすいVRならではの表現。'
                                class3="commentbox_invisible"
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_21cf2af1-be3b-4d9b-9442-3b44079afe0c" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">３種類の光る球体を動かすことで、光と色の重なり合いを体験できるワールドです。<br />好きな重なり合いを見つけてみてください。<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/05NVS31Wgq">pic.twitter.com/05NVS31Wgq</a></p>&mdash; hattori (@tus_soichi) <a href="https://twitter.com/tus_soichi/status/1284807866621194241?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">59</div><div className="workinformation"><div className="worktitle">Let's Just Pokky Game2!![VRAA]</div><div className="workname">Trance_Mode</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Let’s Just Pokky Game2!![VRAA]'
                                creator='Trance_Mode'
                                number='59'
                                backgroundimage={pic59}
                                worldurl='https://vrchat.com/home/world/wrld_6e6f75a1-b311-4392-ba5a-e077d7fb7690'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='誰でも理想のキャラクターとのシチュエーションを叶えることができるのはシンプルに楽しい。細かな設定ができる配慮と作りこみはすごい。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='個人の欲望を包み隠さないワールド。VRChatでここまで設定画面を作り込めるということに驚く。キャラクターが生きているという感覚を感じられないのは積極的に動いてはくれないためか。IPの使い方としてはむしろ商業的な可能性を感じる。'
                                class3="commentbox_invisible"
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_6e6f75a1-b311-4392-ba5a-e077d7fb7690" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">Let&#39;s Just Pokky Game2!![VRAA]<br />200MB以上あり迷いましたが審査員さん達のコメントを聞きたく200以下に抑えた別ワールドを作成致しました！<br />今回のテーマはLive/FrontierですがVR空間に生きるキャラクター達のLiveとして、VRにキャラを生かすという未踏領域として提出させて頂きます！<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/G3G6wUcphz">pic.twitter.com/G3G6wUcphz</a></p>&mdash; Trance_Mode❀@VR観測者 (@trance_mode) <a href="https://twitter.com/trance_mode/status/1284815989696196609?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">60</div><div className="workinformation"><div className="worktitle">サイバー南無南無</div><div className="workname">まどか</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='サイバー南無南無'
                                creator='まどか'
                                number='60'
                                backgroundimage={pic60}
                                worldurl='https://vrchat.com/home/world/wrld_cd551884-e2b5-4db3-9ccc-66d5084d6960'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='坊主の可能性の広さを今一度感じた。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='坊主が担っていた役割をサイバー空間上でも実装するとどうなるか、という挑戦は非常に意義深いものがある。人(坊主)がいないとワールド自体も機能していないというのも、むしろ人の重要さと存在の重みを表しているのではと考えさせられる。反面で、更にもっとバーチャルでしかできないことにもぜひ挑戦してほしいと感じた。'
                                class3="commentbox_invisible"
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_cd551884-e2b5-4db3-9ccc-66d5084d6960" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br /><br />リアルなお坊さんとVRの中で会える。<br />ゆっくり、話ができる。<br />お寺でプロジェクションマッピング×EDM法要を披露していた、サイバー南無南無がVR chatに活動の場を広げました。<br />リアルお坊さんと会えるイベントは2週間に一回開催中<br />お寺を情報空間に移行する為に日々色々な実験中✨ <a href="https://t.co/FpJMJEp4PG">pic.twitter.com/FpJMJEp4PG</a></p>&mdash; まどか♂サイバー南無南無 (@madoka55537) <a href="https://twitter.com/madoka55537/status/1284817963871174659?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">61</div><div className="workinformation"><div className="worktitle">しろハウス</div><div className="workname">しろらな</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='しろハウス'
                                creator='しろらな'
                                number='61'
                                backgroundimage={pic61}
                                worldurl='https://vrchat.com/home/world/wrld_3bc59372-4c5f-48b3-a90c-18873e15feac'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='ボタンで切り替わる黒白の世界はシンプルだが、非常にわかりやすく気持ちの良いビジュアルの面白さを表現している。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='情報量を極限まで減らしたとのことで、3D時代の8-bit的な美的感性を感じた。かたや刺激を求め表現過多になりがちなVRコンテンツだが、必要十分な表現を現実よりも実装しやすいという利点に着目したのはなるほどと得心した。'
                                class3="commentbox_invisible"
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_3bc59372-4c5f-48b3-a90c-18873e15feac" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">「SIRO_HOUSE2」<br />情報量を極限まで削ろうと思って作ったワールドです。<br />ワールドにあるもの<br />・高いタワー<br />・アクションアスレチック<br />・製作者の模した小物（持てる）<br />・製作者を積む台<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><a href="https://t.co/5qwmRXxjIk">https://t.co/5qwmRXxjIk</a></p>&mdash; 白井ららな (@lalanalice) <a href="https://twitter.com/lalanalice/status/1284818061162246144?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">62</div><div className="workinformation"><div className="worktitle">Req․uiem Sanktuary</div><div className="workname">鷹乃みかん</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Req․uiem Sanktuary'
                                creator='鷹乃みかん'
                                number='62'
                                backgroundimage={pic62}
                                worldurl='https://vrchat.com/home/world/wrld_ae846895-b8a3-45c7-9430-21935192b263'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='独自の世界観の廃墟。仮想世界ならではの廃墟感があるのは面白い。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='空に水面が見えている都市空間というのは言外おもしろい。基本的に勝手に人がいるということがないVR世界において、このような退廃的な世界観は環境とマッチして馴染み深いものなのだろう。独特の感性が光る。'
                                class3="commentbox_invisible"
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_ae846895-b8a3-45c7-9430-21935192b263" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">「Req․uiem Sanktuary」 (レクイエム サンクチュアリー)<a href="https://t.co/o4GZdUoP2R">https://t.co/o4GZdUoP2R</a><br /><br />怪物によって世界に終焉がもたらされた世界.<br />住み慣れた街は水没し, 人々も文明も滅んだあと, 生き残ったのはあなたとあなたの友人. これは, 大好きだった街(聖域)と, あなたへの鎮魂歌.<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/MJVSEHPXCr">pic.twitter.com/MJVSEHPXCr</a></p>&mdash; 鷹乃みかん🍊 / VTuber | 8/29 くらげビート5 (@MikanTakano) <a href="https://twitter.com/MikanTakano/status/1284816689780019200?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">63</div><div className="workinformation"><div className="worktitle">Teapot Garden</div><div className="workname">おめが</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Teapot Garden'
                                creator='おめが'
                                number='63'
                                backgroundimage={pic63}
                                worldurl='https://vrchat.com/home/world/wrld_39e163e3-9c29-470e-935d-594c9948c67c'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='心を落ち着かせてくれる癒しの空間。あえて減らした色合いはとても目に優しく、こだわりを感じる。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='ヒューマンスケールに沿った程良い距離感のある空間、VRでしか見られないわずかに不思議さを感じる遠景、耳障りの良いBGM、たしかにそこにいるためのベストプラクティスだった。いまの世情において、本当に必要とされているものはこういうものかもしれない。'
                                class3="commentbox_invisible"
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_39e163e3-9c29-470e-935d-594c9948c67c" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://twitter.com/hashtag/VRChat_world%E7%B4%B9%E4%BB%8B?src=hash&amp;ref_src=twsrc%5Etfw">#VRChat_world紹介</a> <br />「Teapot Garden」<br />VRだからといってだだっ広いだけじゃ落ち着かない。ほどほどの広さと、四方を囲む壁。程よい狭さがある方が&quot;暮らす&quot;には心地良い<br />お茶会モチーフ、モノトーンのお庭ワールドです。Quest対応 <a href="https://t.co/LvGurGAZOc">pic.twitter.com/LvGurGAZOc</a></p>&mdash; おめが（　゜ヮ゜）ノ (@Omegamega) <a href="https://twitter.com/Omegamega/status/1284824593358680067?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">64</div><div className="workinformation"><div className="worktitle">Unstructure</div><div className="workna"><div className="workname">wainoji</div><div className="award">藤井直敬賞</div></div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Unstructure'
                                creator='wainoji'
                                number='64'
                                backgroundimage={pic64}
                                worldurl='https://vrchat.com/home/world/wrld_7af5414d-12f4-4ae0-b19a-1a968d4ecbdb'
                                class="commentbox"
                                commentator='藤井直敬'
                                photo={pic_fujii}
                                commentatortwitttername='@NaotakaFujii'
                                commentatortwittterurl='https://twitter.com/NaotakaFujii'
                                comment='タイトル通りの予測できない構造の変化が面白い。VRならでは。'
                                class2="commentbox"
                                commentator2='吉田修平'
                                photo2={pic_yoshida}
                                commentatortwitttername2='@yosp'
                                commentatortwittterurl2='https://twitter.com/yosp'
                                comment2='上に上りたくなる造りが良かったです。'
                                class3="commentbox"
                                commentator3='豊田啓介'
                                photo3={pic_toyoda}
                                commentatortwitttername3='@toyoda_noiz'
                                commentatortwittterurl3='https://twitter.com/toyoda_noiz'
                                comment3='とても建築的な構成で建築的な制約を相応に残しながら、VRらではの可変性や動きの体験を説明なしに誘発するデザインは秀逸。形そのもの、建築デザイン的な文脈での面白さやこだわりがもう少し盛り込めるともう一段洗練が加わると感じた。'
                                class4="commentbox"
                                commentator4='落合陽一'
                                photo4={pic_ochiai}
                                commentatortwitttername4='@ochyai'
                                commentatortwittterurl4='https://twitter.com/ochyai'
                                comment4='動的な空間建築が気持ち良い．重力を感じた．自発的な探索に没入できる．'
                                class5="commentbox"
                                commentator5='届木ウカ'
                                photo5={pic_uka}
                                commentatortwitttername5='@todoki_uka'
                                commentatortwittterurl5='https://twitter.com/todoki_uka'
                                comment5='カラフルなものが色褪せたようなマテリアルも相まって、子供の頃に町内の色んなところをよじ登って遊んだ原体験を思い出しました。ギミックの形はジェネラティブアートを彷彿とさせるのに、そこに懐かしい色合いをあてたことで「大きくて複雑で、無限によじ登って遊べた町の構造物」の感覚を、大人になって再び味わうことの出来る空間でした。'
                                class6="commentbox"
                                commentator6='龍lilea'
                                photo6={picLilea}
                                commentatortwitttername6='@lileaLab'
                                commentatortwittterurl6='https://twitter.com/lileaLab'
                                comment6='無機的なのに有機的。建築自身の意思で自由に空間を作り出しているかのよう。'
                                class7="commentbox"
                                commentator7='えこちん'
                                photo7={picEcochin}
                                commentatortwitttername7='@ecochin'
                                commentatortwittterurl7='https://twitter.com/ecochin'
                                comment7='壁であり、階段であり、建築であり、変化する風景は常に新しい刺激を見せてくれる。'
                                class8="commentbox"
                                commentator8='sabakichi'
                                photo8={picSabakichi}
                                commentatortwitttername8='@knshtyk'
                                commentatortwittterurl8='https://twitter.com/knshtyk'
                                comment8='壁だと思っていたモノが生きているように動き出す、予想の付かない物理を無視した動的な空間変位はまさにバーチャルらしい表現。一方で、どのような豊かさにフォーカスするのかがむしろ曖昧になるというコンピューテーショナルデザインの問題もそのまま持ち込んでしまっている。ギミックにとどまらず、この先の可能性を感じることのできる作品。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_7af5414d-12f4-4ae0-b19a-1a968d4ecbdb" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br />【Unstructure】<br /><br />生物のように動き定まらない空間を制作しました。<br /><br />我々を守るために不動を貫いた建築／空間が動くことを許された世界で、偶然生まれる強引な空間、なぜか落ち着く空間、または最悪に入り乱れた空間が瞬間的に生まれ、消える様を探し出すことのできるワールドです。 <a href="https://t.co/zoDQYMmklI">pic.twitter.com/zoDQYMmklI</a></p>&mdash; Wainoji (@ya_ss_an) <a href="https://twitter.com/ya_ss_an/status/1284825448707272704?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">65</div><div className="workinformation"><div className="worktitle">個人的なアーカイブを個人的な空間で収蔵する</div><div className="workna"><div className="workname">FUKUKOZY</div><div className="operate">運営作品</div></div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='個人的なアーカイブを個人的な空間で収蔵する'
                                creator='FUKUKOZY'
                                number='65'
                                backgroundimage={pic65}
                                worldurl='https://vrchat.com/home/world/wrld_b80cc7ec-3632-4bed-b745-cb89faf79b2c'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='極めて個人的なものや極めてニッチな主題が実際に空間を持っても良い、そして極めて個なものが公共性を得ても良いというのがVRの良いところなので、「個人的な」にとても同感。宙を舞うブロックたちは生を吹き込まれたようで見惚れる。'
                                class2="commentbox"
                                commentator2='龍lilea'
                                photo2={picLilea}
                                commentatortwitttername2='@lileaLab'
                                commentatortwittterurl2='https://twitter.com/lileaLab'
                                comment2='このワールドのおかげで外出時にコンクリートブロックに目が行くようになってしまった。最後の部屋、まだ展示されていない部屋に入った時の感覚が印象深い。「更にレアなブロックが追加されていくのかも知れない」「どんなデザインだろうか」と想像を巡らせていた。その部屋には何もなかった事で、拡張性を感じられた。'
                                class3="commentbox"
                                commentator3='えこちん'
                                photo3={picEcochin}
                                commentatortwitttername3='@ecochin'
                                commentatortwittterurl3='https://twitter.com/ecochin'
                                comment3='昆虫採集ならぬブロック採集だ。製作者本人の濃いコレクションを垣間見ているようで見ていてとても面白い。このようなワールドが無ければ母国のブロック塀の種類について知る機会はなかったかもしれない。バーチャル空間で空を飛ぶブロックはとても活き活きしており可愛さすら感じる。'
                                class4="commentbox"
                                commentator4='sabakichi'
                                photo4={picSabakichi}
                                commentatortwitttername4='@knshtyk'
                                commentatortwittterurl4='https://twitter.com/knshtyk'
                                comment4='誘導しているようで全くその気がない導線、機械的に並べられたコレクション、どこからともなく飛んでくるブロックたち…博物館と述べられているが、これはMuseumではなくLibraryであった。Boidのように生き生きと空を舞うブロックを間近で眺めていると、作者の偏愛が伝わってくる。極北的な価値観を保管できるというのはVRの良さの一つだと実感した。'
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_b80cc7ec-3632-4bed-b745-cb89faf79b2c" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">concrete block museum<br /><br />個人的なアーカイブを個人的な空間で収蔵すること。<br />2017〜2018年に収集したコンクリート（透かし）ブロックのアーカイブを収蔵する博物館です。<br />今後、アーカイブは増加していくことを見据え、この空間は拡張可能な形式が選択されました。<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/frEFxuA1IO">pic.twitter.com/frEFxuA1IO</a></p>&mdash; FUKUKOZY／Fukuda Koji (@hukukozy) <a href="https://twitter.com/hukukozy/status/1284828612168871938?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">66</div><div className="workinformation"><div className="worktitle">Amebient</div><div className="workna"><div className="workname">雨部 作戦会議課</div><div className="award">大賞</div></div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Amebient'
                                creator='雨部 作戦会議課'
                                number='66'
                                backgroundimage={pic66}
                                worldurl='https://vrchat.com/home/world/wrld_b7416a34-536c-4e59-85da-ce580b6ab0b2'
                                class="commentbox"
                                commentator='藤井直敬'
                                photo={pic_fujii}
                                commentatortwitttername='@NaotakaFujii'
                                commentatortwittterurl='https://twitter.com/NaotakaFujii'
                                comment='とにかく楽しい。デザインがうまい。'
                                class2="commentbox"
                                commentator2='吉田修平'
                                photo2={pic_yoshida}
                                commentatortwitttername2='@yosp'
                                commentatortwittterurl2='https://twitter.com/yosp'
                                comment2='バケツなどを外に配置することで音が変わる素敵なアイデア。癒し空間でした。'
                                class3="commentbox"
                                commentator3='豊田啓介'
                                photo3={pic_toyoda}
                                commentatortwitttername3='@toyoda_noiz'
                                commentatortwittterurl3='https://twitter.com/toyoda_noiz'
                                comment3='自然現象という受動的な状況が、徐々に発見的に能動的なデザイン体験に移行していく発見のプロセスがデザイン対象となっているという点、まさにVRという高次元デザインならではの可能性を感じた。単純に美しいしリズムという原始的なメディウムとVRとの組み合わせも楽しい。'
                                class4="commentbox"
                                commentator4='落合陽一'
                                photo4={pic_ochiai}
                                commentatortwitttername4='@ochyai'
                                commentatortwittterurl4='https://twitter.com/ochyai'
                                comment4='水をテーマに落ちたり鳴らしたり探索性の広い空間芸術だった．'
                                class5="commentbox"
                                commentator5='届木ウカ'
                                photo5={pic_uka}
                                commentatortwitttername5='@todoki_uka'
                                commentatortwittterurl5='https://twitter.com/todoki_uka'
                                comment5='雰囲気系のワールドかと思いきや、色んな遊び要素がさりげなく散りばめられていることや、リスポーン時の演出が滑らかなものに変更されていることなど、心地良くてずっと居たいと思える環境への追求が丁寧でした。自分が心地よいと感じた音の調和が、すぐに他の人に乱されてしまったのが面白かったです。'
                                class6="commentbox"
                                commentator6='えこちん'
                                photo6={picEcochin}
                                commentatortwitttername6='@ecochin'
                                commentatortwittterurl6='https://twitter.com/ecochin'
                                comment6='雨粒によってできあがる偶発的な音楽が至極楽しい。消えゆくような世界の中に存在しながら、そこには言葉では表しきれない美しさがある極めて完成度の高い作品だ。'
                                class7="commentbox"
                                commentator7='sabakichi'
                                photo7={picSabakichi}
                                commentatortwitttername7='@knshtyk'
                                commentatortwittterurl7='https://twitter.com/knshtyk'
                                comment7='偶発性と恣意性、そして繊細な感性の心地良い同居に美学を感じる。一見してノングルーヴサウンドが構築されるかと思いきや、極めて濃い作家的な作為によって緻密に構築された音たちの作品的構成が立ち現れてくる、極めて快適なインタラクティブ体験だ。現実ではこの作為には到達できまい。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_b7416a34-536c-4e59-85da-ce580b6ab0b2" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">- Amebient -<br />雨と音。そして終わりかけの世界があります。 <br />Rain and sound, world to end.<a href="https://t.co/82wvadxrcg">https://t.co/82wvadxrcg</a><br /><br /> <a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/pDNVmsxRbV">pic.twitter.com/pDNVmsxRbV</a></p>&mdash; らくとあいす (@rakuraku_vtube) <a href="https://twitter.com/rakuraku_vtube/status/1284823157036118017?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">67</div><div className="workinformation"><div className="worktitle">中銀パラレルタワー</div><div className="workname">たかし企画</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='中銀パラレルタワー'
                                creator='たかし企画'
                                number='67'
                                backgroundimage={pic67}
                                worldurl='https://vrchat.com/home/world/wrld_d0cf657a-d7f2-4469-9776-33c1149cb179'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='現実にあってこその不可思議なデザインが魅力であるが、バーチャルでの姿はむしろ現実に縛られているようにも見える。バーチャル上で再構築するにあたり、より分解されると魅力がでてきそう。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='真の姿とまで言い切られるとそうなのかもと多少でも考えてしまうところがこそばゆい。既存の建築作品を脱構築する試みは正統な建築文化の営みであり、モチーフになった建築にはない感性が体験できる点は良いが、対比や批評性は薄く、オリジナルに帰依する必要があったのか疑問に感じてしまった。VR的メタボリズムを記述してくれたら、また違う表現となったかもしれないという期待を感じた。'
                                class3="commentbox_invisible"
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_d0cf657a-d7f2-4469-9776-33c1149cb179" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a>「CapsuleTower」<br /><br />・あの日見た芳ばしい夏の調べと共に訪れる水族館<br />・六畳一間でも、なぜかワクワクに満ち溢れた若き頃<br />・建築家の巨匠「黒川紀章」が夢見た「中銀カプセルタワー」の真の姿<br /><br />自然と旧世代が対応した美学。それら<br />人間社会を送り、窓越しに瞬間を切り取る住まいです。 <a href="https://t.co/qBf6TTd7Wj">pic.twitter.com/qBf6TTd7Wj</a></p>&mdash; たかし企画@現実とバーチャルの親和性を開発 (@hatashi306) <a href="https://twitter.com/hatashi306/status/1284839315919405058?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">68</div><div className="workinformation"><div className="worktitle">O倉山_MT․O-KURA</div><div className="workname">MuroMachi Store (machi_syo)</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='O倉山_MT․O-KURA'
                                creator='MuroMachi Store (machi_syo)'
                                number='68'
                                backgroundimage={pic68}
                                worldurl='https://vrchat.com/home/world/wrld_9db596e1-2a2b-414f-8b7c-782b4cf8b994'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='自然を模した作為的な風景に、不思議と新鮮さを感じることができた。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='すべてが人工物で構成されているという前提の世界において、風景を楽しむ、ということがどういうことなのかについて考えさせられる。ポリゴンに美醜の感性が宿るとき、フロンティアに住まう人間としての格を手にすることができるのかもしれない。'
                                class3="commentbox_invisible"
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_9db596e1-2a2b-414f-8b7c-782b4cf8b994" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">【O倉山_MT․O-KURA】<br /><br />散歩中に体感した木陰から、<br />風にゆらめく影と日向をイメージしたワールドです。<br />/光担当:Muro_syo<br /><br />空を見上げてぼーっとしていたい空間を山の中につくってみた（法線の向きを楽しんで）。<br />/闇担当:Machi_syo<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://twitter.com/hashtag/VRAA02?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02</a> <a href="https://t.co/yCvYiyiNLf">pic.twitter.com/yCvYiyiNLf</a></p>&mdash; 町田賢俊 Kenshun MACHIDA (@machi_syo_g) <a href="https://twitter.com/machi_syo_g/status/1284841947429888006?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">69</div><div className="workinformation"><div className="worktitle">VR MANGA ＂APPLE BUNNY＂</div><div className="workna"><div className="workname">小江華あき</div><div className="award">Finalist</div></div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='VR MANGA ＂APPLE BUNNY＂'
                                creator='小江華あき'
                                number='69'
                                backgroundimage={pic69}
                                worldurl='https://vrchat.com/home/world/wrld_b00833a2-a7fb-4865-be9a-c251a3875e78'
                                class="commentbox"
                                commentator='藤井直敬'
                                photo={pic_fujii}
                                commentatortwitttername='@NaotakaFujii'
                                commentatortwittterurl='https://twitter.com/NaotakaFujii'
                                comment='空間のダイナミックさと、見えなくする工夫が素晴らしい。'
                                class2="commentbox"
                                commentator2='吉田修平'
                                photo2={pic_yoshida}
                                commentatortwitttername2='@yosp'
                                commentatortwittterurl2='https://twitter.com/yosp'
                                comment2='面白くて２周してしまいました。もっと見たい。'
                                class3="commentbox"
                                commentator3='豊田啓介'
                                photo3={pic_toyoda}
                                commentatortwitttername3='@toyoda_noiz'
                                commentatortwittterurl3='https://twitter.com/toyoda_noiz'
                                comment3='昨年に続き技術と体験の質、作り込みの精度や全体をひとつの体験のストーリーへと昇華する力量は圧倒的。昨年より洗練度が高まった分、新しい驚きを盛り込むことはよりハードルが上がっているのも感じる。体験者みずからがみられる側になるという視点の転換を漫画に持ち込むというのは、高次元のストーリーデザインの一手法としてとても興味深いと感じた'
                                class4="commentbox"
                                commentator4='落合陽一'
                                photo4={pic_ochiai}
                                commentatortwitttername4='@ochyai'
                                commentatortwittterurl4='https://twitter.com/ochyai'
                                comment4='漫画の枠を超えて，VRとしての作り込みが良かった．'
                                class5="commentbox"
                                commentator5='届木ウカ'
                                photo5={pic_uka}
                                commentatortwitttername5='@todoki_uka'
                                commentatortwittterurl5='https://twitter.com/todoki_uka'
                                comment5='「木に登る」「穴に落ちる」「空高く舞い上がる」などの漫画の中の出来事を、実際の動作を伴って読むことが出来たのは良い読書体験でした。シナリオは、ギミックのための最低限の内容のように感じたので、ご自身の好きを突き詰めた漫画も読んで/体感してみたいです。'
                                class6="commentbox"
                                commentator6='えこちん'
                                photo6={picEcochin}
                                commentatortwitttername6='@ecochin'
                                commentatortwittterurl6='https://twitter.com/ecochin'
                                comment6='驚異的な体験だ。バーチャル世界で漫画を読む行為を更に一歩前進させ、キャラクターと一緒に物語を体験できるインタラクティブ性の高さはもはや唯一無二だ。'
                                class7="commentbox"
                                commentator7='sabakichi'
                                photo7={picSabakichi}
                                commentatortwitttername7='@knshtyk'
                                commentatortwittterurl7='https://twitter.com/knshtyk'
                                comment7='VRで再解釈されたマンガ表現がさらに一歩先に踏み込み、ユニークな表現の領域にまで到達した感がある。脳が慣れるまで多少時間が掛かったが、漫画の新しい表現というよりは、新しい表現のために漫画的技法を用いているという表現の関係が構築されていて、真に漫画空間に参加している感覚が得られる。驚きの体験。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_b00833a2-a7fb-4865-be9a-c251a3875e78" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br /><br />VR MANGA ＂APPLE BUNNY＂<br /><br />VRペイントアプリ「TiltBrush」を使用して描かれた漫画空間を読み進んでいくワールドです。<br />主人公の兎を追いかけるように進む「体験する漫画」をお楽しみください🐇<a href="https://t.co/LRUw6OqMXs">https://t.co/LRUw6OqMXs</a> <a href="https://t.co/zydLHzKj1f">pic.twitter.com/zydLHzKj1f</a></p>&mdash; 小江華あき🍁VRおえかき部 (@OEKA_AKI) <a href="https://twitter.com/OEKA_AKI/status/1284845457558999041?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">70</div><div className="workinformation"><div className="worktitle">Skyscraper night pool</div><div className="workname">ありしあ</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Skyscraper night pool'
                                creator='ありしあ'
                                number='70'
                                backgroundimage={pic70}
                                worldurl='https://vrchat.com/home/world/wrld_44a80703-f703-439e-b588-1a076fde8b11'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='人生でこんな素敵な場所で過ごす期間があるのかはわからないが、ここでは理想のナイトプールに入ることができて最高だ。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='私はナイトプールに行ったことがないし、作者もそうであるらしい。しかしこれは実質的にナイトプールであり、想像上のマリーナベイサンズに抱くイデアをそのまま空間へと変換したという意味においてはこれもまた実質的にナイトプールと言えるのだと思う。'
                                class3="commentbox_invisible"
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_44a80703-f703-439e-b588-1a076fde8b11" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">【VRAA02参加するよ】「Skyscraper night pool」<br />共同作品<br /><br />ナイトプールに一度も行った事がない製作者による、夢や希望の詰まったリゾート<br />開発中につきprivateですが、public化を目指しています<br />この夏、摩天楼に浮かぶナイトプールで遊ぼう!<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/UCOBuSjzRf">pic.twitter.com/UCOBuSjzRf</a></p>&mdash; 亜空間ゼウス (@HyperspaceZeus) <a href="https://twitter.com/HyperspaceZeus/status/1284846407615037440?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">71</div><div className="workinformation"><div className="worktitle">旧都城市民会館VR</div><div className="workna"><div className="workname">龍 lilea</div><div className="operate">運営作品</div></div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='旧都城市民会館VR'
                                creator='龍 lilea'
                                number='71'
                                backgroundimage={pic71}
                                worldurl='https://vrchat.com/home/world/wrld_cb7fc2ae-2262-41ef-841a-5d2a1ffb0d87'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='フォトグラメトリ等で現実の場所をバーチャルに移植する試みは多くなってきた中で、この旧都城市民会館はその再現度においてある一線を超えた感がある。この（現実側の姿を失った）デジタルツインがあれば、あとは地域の人が実際に使って経済が回る仕組みをどう作るかに課題をシフトさせていきたい。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='フォトグラメトリとしての体験はこの作品によってまた1段階高い次元へと高まったと思ってしまうほど。ワールド内に点在する利用者の在りし日の写真とコメントによって、時間をも超えた驚異的な体験を与えてくれる。'
                                class3="commentbox"
                                commentator3='sabakichi'
                                photo3={picSabakichi}
                                commentatortwitttername3='@knshtyk'
                                commentatortwittterurl3='https://twitter.com/knshtyk'
                                comment3='取り組みの意義深さは間違いないが、導入部の構成はアーカイブとはかくあるべきと思わせてくれる素晴らしい差配だった。テクスチャのシミに宿った時間経過の重みが、実質的に建築が存在し続けられていることを証明しているように思える。'
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_cb7fc2ae-2262-41ef-841a-5d2a1ffb0d87" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">菊竹清訓氏設計<br />解体となった旧｢都城市民会館｣をVR化しました。<br /><br />地下から屋根裏まで､全ての部屋を巡れます。<br /><br />空間＋時をアーカイブ。<br />&quot;人と建築の記憶&quot;に触れてみてください。<a href="https://twitter.com/hashtag/%E6%97%A7%E9%83%BD%E5%9F%8E%E5%B8%82%E6%B0%91%E4%BC%9A%E9%A4%A8VR?src=hash&amp;ref_src=twsrc%5Etfw">#旧都城市民会館VR</a> <a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/GN7hJNRY8O">pic.twitter.com/GN7hJNRY8O</a></p>&mdash; 龍 lilea (@lileaLab) <a href="https://twitter.com/lileaLab/status/1284844066899808257?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">72</div><div className="workinformation"><div className="worktitle">COMPOSITION FOR RESONATING METAVERSE NO․1</div><div className="workname">zakim zey</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='COMPOSITION FOR RESONATING METAVERSE NO․1'
                                creator='zakim zey'
                                number='72'
                                backgroundimage={pic72}
                                worldurl='https://vrchat.com/home/world/wrld_e8ee5a85-50bb-446a-bb4d-5ce8c4ea6203'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='偶然性からなる音楽'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='音が出るというシンプルな面白さはあるが、期待を超えてくるものではなかった。空間のスケールとインタラクティブ性が両立すると、よりコンセプトを体現したものになるのではという期待がある。'
                                class3="commentbox_invisible"
                                class4="commentbox_invisible"
                                class5="commentbox_invisible"
                                class6="commentbox_invisible"
                                class7="commentbox_invisible"
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_e8ee5a85-50bb-446a-bb4d-5ce8c4ea6203" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">この空間では、音と光によって綾なされる、未踏の &quot;Live&quot; セッションを体験することができます。<br />メタバースの空間表現に偶発性を組み込むことで、コミュニケーションの拡張可能性を問います。<br />操作：音の雫は色の柱から得ることができます。<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><a href="https://t.co/dnKJ7rshaC">https://t.co/dnKJ7rshaC</a> <a href="https://t.co/wA6YYcB61K">pic.twitter.com/wA6YYcB61K</a></p>&mdash; zakim zey (@ZakimZey) <a href="https://twitter.com/ZakimZey/status/1284851157471424513?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">73</div><div className="workinformation"><div className="worktitle">Virtual Music Visualization</div><div className="workna"><div className="workname">skkn</div><div className="award">吉田修平賞</div></div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Virtual Music Visualization'
                                creator='skkn'
                                number='73'
                                backgroundimage={pic73}
                                worldurl='https://vrchat.com/home/world/wrld_31672c4b-9eac-4b13-9055-3e078507d0f3'
                                class="commentbox"
                                commentator='吉田修平'
                                photo={pic_yoshida}
                                commentatortwitttername='@yosp'
                                commentatortwittterurl='https://twitter.com/yosp'
                                comment='純粋に楽しめました。列車の窓から外を覗く感覚が良かった。'
                                class2="commentbox"
                                commentator2='藤井直敬'
                                photo2={pic_fujii}
                                commentatortwitttername2='@NaotakaFujii'
                                commentatortwittterurl2='https://twitter.com/NaotakaFujii'
                                comment2='これからはこうなるんだろうなという感じ。とても良くできている。'
                                class3="commentbox"
                                commentator3='豊田啓介'
                                photo3={pic_toyoda}
                                commentatortwitttername3='@toyoda_noiz'
                                commentatortwittterurl3='https://twitter.com/toyoda_noiz'
                                comment3='実空間では不可能な音体験の演出を没入型で生成し、さらに車内と車外という異なる世界をその中に入れ子状に作ることでよりその異世界感を高めることに成功している。選ぶ音楽に応じて体験世界が変わる前提で日常的にここに音楽に浸りに行きたい。純粋に欲しい。'
                                class4="commentbox"
                                commentator4='落合陽一'
                                photo4={pic_ochiai}
                                commentatortwitttername4='@ochyai'
                                commentatortwittterurl4='https://twitter.com/ochyai'
                                comment4='目で見る音楽感が良かった'
                                class5="commentbox"
                                commentator5='届木ウカ'
                                photo5={pic_uka}
                                commentatortwitttername5='@todoki_uka'
                                commentatortwittterurl5='https://twitter.com/todoki_uka'
                                comment5='同じ技法で別の音楽分野の表現を行ったらどうなるか見てみたいですが、これはおそらく容易く人を刺せてしまえるやり方なので、体験するのが怖くもあります。それだけ強烈な体験でした。'
                                class6="commentbox"
                                commentator6='えこちん'
                                photo6={picEcochin}
                                commentatortwitttername6='@ecochin'
                                commentatortwittterurl6='https://twitter.com/ecochin'
                                comment6='最高にハイになれるMVだ。電車の中という閉ざされた空間を上手く活かした演出がとにかく気持ちいい。1周の時間も最適。'
                                class7="commentbox"
                                commentator7='sabakichi'
                                photo7={picSabakichi}
                                commentatortwitttername7='@knshtyk'
                                commentatortwittterurl7='https://twitter.com/knshtyk'
                                comment7='MVならぬミュージック空間を体感できる。将来的なデファクトスタンダートの形式を予感させる視聴体験。インタラクティブ性の低さなども、リテラシーのあまり高くないユーザにとっては高い利点。他視点型のMVなど、従来メディアが実現し得なかったことをついに実現してくれている作品であり、リファレンスのマケットとして非常に価値が高い体験だと思う。'
                                class8="commentbox"
                                commentator8='masanaga'
                                photo8={picMasanage}
                                commentatortwitttername8='@tasklong'
                                commentatortwittterurl8='https://twitter.com/tasklong'
                                comment8='日常にある光景から変化する景色、閉ざされた空間の中で浴びる光と音がとても心地いい。外には激しい世界があるのにそれを小さい窓から覗いているだけと言うのも微妙に日常からはみ出てない感じがなんだかいい。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_31672c4b-9eac-4b13-9055-3e078507d0f3" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">[VMV] CC TYPE01<br />バーチャル ミュージック ビジュアライザー<br /><br />人類全員がこのぐらいの体験を当たり前になりますように<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/VJz6eVKnMz">pic.twitter.com/VJz6eVKnMz</a></p>&mdash; skkn (@skknyuki) <a href="https://twitter.com/skknyuki/status/1284855168018771969?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">74</div><div className="workinformation"><div className="worktitle">Jogashima Photogrammetry Coast</div><div className="workname">もりのこ</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Jogashima Photogrammetry Coast'
                                creator='もりのこ'
                                number='74'
                                backgroundimage={pic74}
                                worldurl='https://vrchat.com/home/world/wrld_64aa0f6f-dc18-42d8-865b-fee20282c66c'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='とても広大なフォトグラメトリ。実際に行ったことがあるが、特徴的な海岸が見事に再現されており、没入感が高い。実際には乗れない馬の背の上にも乗れて感激。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='歩いていくのにとても時間が掛かった。それが非常に良い。実際に訪れこの場所を歩いた時にも感じたであろう道程の面倒さや身体に掛かる負担が実質的に再現されているということだ。フォトグラメトリはジオメトリをつくる技術だと思われているが、本質的には体験を変換し追想可能であるということを本作品は体現している。ジオメトリの荒さも、空間的な記憶の解像度と一致しているだろう。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='とても広く、現実だったらこの場所をこんなに歩き回る事とはないだろう。だからこそ現地に対して興味を持った。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_64aa0f6f-dc18-42d8-865b-fee20282c66c" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">[Jogashima Photogrammetry Coast] <br /><br />光の反射、音、ぜんぶあわさってその場の記憶が残るとたのしい<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/Rlsc10llN9">pic.twitter.com/Rlsc10llN9</a></p>&mdash; もりのこ🌞 (@coacoamol_vrc) <a href="https://twitter.com/coacoamol_vrc/status/1284855292627349504?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">75</div><div className="workinformation"><div className="worktitle">落ち着く汚れ</div><div className="workname">あやしいみつ</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='落ち着く汚れ'
                                creator='あやしいみつ'
                                number='75'
                                backgroundimage={pic75}
                                worldurl='https://vrchat.com/home/world/wrld_4b67b6f3-ebde-4f9f-aaf3-91ba0c4696e1'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='落ち着きのある茶室。初めから全てが見えない所に日本人としてとても好感が持てる。だからこそ障子を開けた時の風景のインパクトはもっとあってもよいかもしれないと感じた。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='ぽっかりと切り取られた茶室だ。それ以上でもそれ以下でもない。茶室という完成された様式は再現するだけで茶室として機能するということを示してくれている。借景がないところが不思議な独特の違和感がある。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='何をする空間かと考えたが、何もしなくていい空間なのだろう。ただ鹿威しの音を聞くだけでもその空間に浸れる。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_4b67b6f3-ebde-4f9f-aaf3-91ba0c4696e1" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br />小さな和風の部屋です<br />少人数でのんびりするときのどうぞ<br />すこし古びて汚れていますが、それもまたわびさびというものでしょう<br />VR世界の全ての物がピカピカの新品だったら、なんだか飽きてしまいそうですしね<br />多少汚れていたほうが、人間は落ち着くのかもしれません <a href="https://t.co/eM21pMUA8c">pic.twitter.com/eM21pMUA8c</a></p>&mdash; あやしいみつ/A-honey.tech (@ayashiimitsu) <a href="https://twitter.com/ayashiimitsu/status/1284855683330871297?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">76</div><div className="workinformation"><div className="worktitle">夏キャスマーケット</div><div className="workname">夏キャスマーケット2020（ベッラパスタ）</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='夏キャスマーケット'
                                creator='夏キャスマーケット2020（ベッラパスタ）'
                                number='76'
                                worldurl='https://id.pokemori.jp/virtualcast-fes/market.xhtml'
                                class="commentbox"
                                commentator='sabakichi'
                                photo={picSabakichi}
                                commentatortwitttername='@knshtyk'
                                commentatortwittterurl='https://twitter.com/knshtyk'
                                comment='動画での評価。バーチャル上でのコミュニティマーケットイベントの開催が一般化してきたというのはシンプルに好感が持てる。一方で、早くも形式化してきている様子に見て取れる。オリジナリティがどこに宿るのか期待したい。'
                                class2="commentbox"
                                commentator2='masanaga'
                                photo2={picMasanage}
                                commentatortwitttername2='@tasklong'
                                commentatortwittterurl2='https://twitter.com/tasklong'
                                comment2='ユーザーコミュニティとして即売会形式と言うのは理解されやすいだろう。ユーザー・クリエーター双方の利益のために様々なプラットフォームで行われ、いずれプラットフォームを問わず開催できる未来が来ることを願っている。'
                            />
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">「夏キャスマーケット」<br />バーチャルキャストは審査対象ではありませんが、<br />将来必ず審査対象になるでしょう。<br />VR上のマーケットは未来のイベントですが、<br />将来当たり前のイベントになるでしょう。<a href="https://twitter.com/hashtag/VRAA02?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02</a> <a href="https://twitter.com/hashtag/%E3%83%90%E3%83%BC%E3%83%81%E3%83%A3%E3%83%AB%E3%82%AD%E3%83%A3%E3%82%B9%E3%83%88?src=hash&amp;ref_src=twsrc%5Etfw">#バーチャルキャスト</a> <a href="https://twitter.com/hashtag/%E5%A4%8F%E3%82%AD%E3%83%A3%E3%82%B9?src=hash&amp;ref_src=twsrc%5Etfw">#夏キャス</a> <a href="https://t.co/Kxn5DYndcs">pic.twitter.com/Kxn5DYndcs</a></p>&mdash; ベッラパスタ@8月は夏キャス📡 (@VR_pasta) <a href="https://twitter.com/VR_pasta/status/1284859757270986754?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">77</div><div className="workinformation"><div className="worktitle">ねこがいる家の,日常の記録展.</div><div className="workname">チョイチョイ</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='ねこがいる家の,日常の記録展.'
                                creator='チョイチョイ'
                                number='77'
                                backgroundimage={pic77}
                                worldurl='https://vrchat.com/home/world/wrld_0785a852-14f7-4926-8a20-8c5b0e57cab0'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='VRならではの写真展示。見るだけではなく、触れそうな実体感がとても可愛らしい。高い技術を用いつつもそれを感じさせない自然さが良い。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='これはVR時代の写真展だ。生物のフォトグラメトリというのは技術的難度が高く、目にする機会に乏しい。ねこを自慢したい、あくまで私的な記録の開放であるという一部の写真展が利用してきた文脈の狡猾さを活用して、品質を評価から外すことに成功している点が巧みである。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='フォトグラメトリで猫の柔らかさを感じられるのはとても興味深く感じた。動物のフォトグラメトリは得も言われぬ不気味な印象を持ったりもするがそういう事もなく、猫独特の癒しを得られる。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_0785a852-14f7-4926-8a20-8c5b0e57cab0" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">ねこがいる家の,日常の記録展.<br /><br />”Live/Frontier”という募集テーマの元，2匹の猫の”今の暮らし”を”フォトグラメトリ”で記録し，未来の自分に残しました．ついでに世界中に自慢します．<br /><br />VR世界の一端に作った，アルバムのちょっとだけ新しい形を，少しでも楽しんで頂けたら幸いです．<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/qpVhwKq2E4">pic.twitter.com/qpVhwKq2E4</a></p>&mdash; ﾁｮｲﾁｮｲ (@archi_peace) <a href="https://twitter.com/archi_peace/status/1284859371927683078?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">78</div><div className="workinformation"><div className="worktitle">Around the Mysterious Structure</div><div className="workna"><div className="workname">ASVR project （岸江浩太朗）</div><div className="award">佳作</div></div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Around the Mysterious Structure'
                                creator='ASVR project（岸江浩太朗）'
                                number='78'
                                backgroundimage={pic78}
                                worldurl='https://vrchat.com/home/world/wrld_82aec487-4422-4481-a9ec-02cfed5e7505'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='VRアート展示　展示も体験も一緒にできる'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='正統なアート展示として成立している。一方で、基底現実で成立した法則に暗黙的に従っている節があり、バーチャル空間におけるサイトスペシフィックの不在についてが組み込まれておらず、少々消化不良感も否めない。けれども非常に丁寧につくられたワールドや、音効の気の利き具合、展示としての内容が機能している点で、実は希少であることには違いない。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='LIVE / FRONTIREのテーマをしっかりと咀嚼した作品だと感じた。美術館では絵の前に立ちどういう世界なのか想像し想いを馳せるものだが、作品の中に入ってそれをすると言うのはとても不思議な感覚があった。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_82aec487-4422-4481-a9ec-02cfed5e7505" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">Around the Mysterious Structure<br />辺境とギャラリーを往来し,ヘリや気球,山頂から謎の構造物を見たりシュールな体験をすることができます.それらの体験をする他のユーザーを見たり,あなた自身が見られたりしながら,いろんな「生きている感」を感じてもらえればと思って制作しました. <a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/r6n9GI5cfx">pic.twitter.com/r6n9GI5cfx</a></p>&mdash; ASVR Project (@ASVR26967673) <a href="https://twitter.com/ASVR26967673/status/1284857951790837760?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">79</div><div className="workinformation"><div className="worktitle">World of Diversity</div><div className="workname">イカめし</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='World of Diversity'
                                creator='イカめし'
                                number='79'
                                backgroundimage={pic79}
                                worldurl='https://vrchat.com/home/world/wrld_98fcabe1-d750-4c7e-b0ea-3ac201446887'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='VRでの生活を抽象化し、集めたようなワールド。宇宙にあることからもうリアルでは終末を迎え、体験できなくなった世界を見ているかのような美しさがある。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='作者さんのよくばりワールド。普通なら複数にしてしまう別々の異なる暮らしの姿を一つにまとめて、切り替えることができる。この全く異質なもの同士が入れ替わるというのが、この世界の許容度の広さを体現しているように思えた。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='ここでは3つの選択肢があったが、可能性としてはより多くの選択肢が存在することを感じさせてくれる。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_98fcabe1-d750-4c7e-b0ea-3ac201446887" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br /><br />【World of Diversity】<br /><br />そこに住む人の数だけ選択肢の増えるVR世界<br />リアル世界の現実的な制限からの解放により<br />違う街の住民になったり、自由に芸術を鑑賞したり、ヒーローにだってなれる、<br />そんな自由な存在を手に入れる事のできるVR住民VRAAとしての未来を表現したワールドです <a href="https://t.co/OAVv0sZVWM">pic.twitter.com/OAVv0sZVWM</a></p>&mdash; イカめし🦑バーチャルワールドクリエイター🦑 (@ikameshi_vr) <a href="https://twitter.com/ikameshi_vr/status/1284863897434906627?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">80</div><div className="workinformation"><div className="worktitle">はがせるポリゴン</div><div className="workname">三田村綯夜</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='はがせるポリゴン'
                                creator='三田村綯夜'
                                number='80'
                                backgroundimage={pic80}
                                worldurl='https://vrchat.com/home/world/wrld_8926905e-cccd-4de2-ac7d-3c5448ef3b59'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='単純な形状からは想像がつかないほどに見た事がないビジュアル・やった事がない体験が展開される面白さがある。技術単体で終わっているのがとても惜しい。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='いままでできなかったことができるというのは、それだけで新しい視点を提供してくれる。ポリゴンジオメトリはすでにできあがったものであるという固定観念を崩してくれた。特にCubeを伸び縮みさせられるのは空間の変質がリアルタイムに実感できてよかった。技術デモで留まっているのが惜しい。この表現を活かした作品が登場することに期待したい。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='「ポリゴンを剥がす」という表現が面白い。空間を変形させることに意味を持つのはVRならではだと思う。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_8926905e-cccd-4de2-ac7d-3c5448ef3b59" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">ポリゴンが剥がせるワールドを作りました<br />四つあります<a href="https://t.co/eW4oLWJ84l">https://t.co/eW4oLWJ84l</a> <a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/2Ma8W5QqsU">pic.twitter.com/2Ma8W5QqsU</a></p>&mdash; 三田村綯夜📖VR小説書き (@kuzusaki) <a href="https://twitter.com/kuzusaki/status/1284627317520646144?ref_src=twsrc%5Etfw">July 18, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">81</div><div className="workinformation"><div className="worktitle">House of Roiko</div><div className="workname">空風ふうた</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='House of Roiko'
                                creator='空風ふうた'
                                number='81'
                                backgroundimage={pic81}
                                worldurl='https://vrchat.com/home/world/wrld_60384017-7238-4d47-b897-f8d8eee52544'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='自分なりの世界観を作るとはこういう事であり、細部にわたり来た人を楽しませようとする拘りがとても愛おしい。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='とても可愛らしい世界観だ。テラスまで辿り着いてはじめてそこがカタツムリの背中であることに気がつく演出も憎い。ともすれば作品とは言えない好きなものを置いた私的な世界なのかもしれないが、純粋な世界観や安心感を表現したという点と特異な趣味性において、LIVE/FRONTIERというテーマに高く合致している。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='寄生された宿主にさらに寄生して暮らすという発想はなかなかすごい。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_60384017-7238-4d47-b897-f8d8eee52544" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">VRの世界ではあなたはどこにだって行けるし、どんな姿にもなれる。このワールドは寄生されたカタツムリに暮らす少女たちのおうちです。<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/lpz8pl3Xau">pic.twitter.com/lpz8pl3Xau</a></p>&mdash; 空風ふうた (@sorakazehuuta) <a href="https://twitter.com/sorakazehuuta/status/1284864514752540672?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">82</div><div className="workinformation"><div className="worktitle">Downtown_ATAKAMO</div><div className="workname">ATAKAMO (ATAKAMO-CHAN)</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Downtown_ATAKAMO'
                                creator='ATAKAMO (ATAKAMO-CHAN)'
                                number='82'
                                backgroundimage={pic82}
                                worldurl='https://vrchat.com/home/world/wrld_3c055f64-60b3-400f-91b8-797e486bb675'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='着替える服だけでなく、服を展示するスペースそのものも美しく形成されており純粋にかっこいい。周りの建物・街につくりかけ感を感じてしまうのが惜しい。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='趣味で制作したモノの展示ワールドだろうか。それ以上の意味を見いだせなかったが、展示の方法は現実ではなかなかできないものが多く、興味深かった。区域をつくるならば、内部空間だけでなく、街側の設計も必要ではないかという印象を持ちました。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='現実のアパレルショップでは実現できないギミックで服を探す楽しさがある。地下鉄駅にあるキャットウォークはその意味を探したくなる。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_3c055f64-60b3-400f-91b8-797e486bb675" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br />バーチャル丁寧な暮らしを求めて。<br />バーチャルなライフスタイルを提案出来るようにバーチャルな文化商業区域を目指しています。 <a href="https://t.co/VyPQPNYaoT">pic.twitter.com/VyPQPNYaoT</a></p>&mdash; Virtual Mammalian (@atakamo_vtuber) <a href="https://twitter.com/atakamo_vtuber/status/1284864251480293376?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">83</div><div className="workinformation"><div className="worktitle">Mayakan Volumetric</div><div className="workna"><div className="workname">VoxelKei</div><div className="award">豊田啓介賞</div></div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Mayakan Volumetric'
                                creator='VoxelKei'
                                number='83'
                                backgroundimage={pic83}
                                worldurl='https://vrchat.com/home/world/wrld_9cbf7204-55e0-43ab-bd8b-a264306c9fbc'
                                class="commentbox"
                                commentator='豊田啓介'
                                photo={pic_toyoda}
                                commentatortwitttername='@toyoda_noiz'
                                commentatortwittterurl='https://twitter.com/toyoda_noiz'
                                comment='これも昨年に続いて圧倒的な技術力と実装力で言うことなしのワールド構築力。空気感という、3Dを越えた体験空間をデザインする汎用技術開発という、なかなかできそうでできない領域に継続的に取り組んでいることはもう評価というより尊敬。'
                                class2="commentbox"
                                commentator2='吉田修平'
                                photo2={pic_yoshida}
                                commentatortwitttername2='@yosp'
                                commentatortwittterurl2='https://twitter.com/yosp'
                                comment2='フォトグラメトリは大好きです。神戸？の夜景が綺麗でした。'
                                class3="commentbox"
                                commentator3='藤井直敬'
                                photo3={pic_fujii}
                                commentatortwitttername3='@NaotakaFujii'
                                commentatortwittterurl3='https://twitter.com/NaotakaFujii'
                                comment3='廃墟の女王が目の前に！新しい見せ方がスゴイ。'
                                class4="commentbox"
                                commentator4='落合陽一'
                                photo4={pic_ochiai}
                                commentatortwitttername4='@ochyai'
                                commentatortwittterurl4='https://twitter.com/ochyai'
                                comment4='空気感が切り取れていた．レンダリング，フォトグラメトリの相性が絶妙でどこか懐かしくどこか異次元感があり気持ち良い．'
                                class5="commentbox"
                                commentator5='届木ウカ'
                                photo5={pic_uka}
                                commentatortwitttername5='@todoki_uka'
                                commentatortwittterurl5='https://twitter.com/todoki_uka'
                                comment5='フォトグラメトリの不十分な空間再現性を、空気感のある光で補う演出が見事でした。空模様で光の加減が変わったり、懐中電灯で照らしたりなど、「そこで確かにそう体験した人がいたのであろう」空間演出が（思い出補正を含め）多く盛り込まれていて、ただフォトグラメトリのメッシュを等身大で眺めるだけではない、また別の体験を味わいました。'
                                class6="commentbox"
                                commentator6='えこちん'
                                photo6={picEcochin}
                                commentatortwitttername6='@ecochin'
                                commentatortwittterurl6='https://twitter.com/ecochin'
                                comment6='単なるフォトグラメトリにとどまらない空気感が本当に気持ち良い'
                                class7="commentbox"
                                commentator7='sabakichi'
                                photo7={picSabakichi}
                                commentatortwitttername7='@knshtyk'
                                commentatortwittterurl7='https://twitter.com/knshtyk'
                                comment7='空気を表現することにフォーカスしたというトライが有効に機能しており、空気の流れなど知覚可能な非言語の領域が拡張されているのが素晴らしい。残念なのは音響が不足していることで、大部分の知覚的認識が失われてしまっていると思われる点。ここまでの表現のポテンシャルを見せつけられると、これの体験が一体どのような特異な意味を齎すのかまで踏み込んでくれることを期待してしまう。フォトリアルなCGがもつ「本物ではない本物」という構造が持つ限界が解決されれば、もっと人生に影響を及ぼす体験に昇華されるのではないかという期待がある。'
                                class8="commentbox"
                                commentator8='masanaga'
                                photo8={picMasanage}
                                commentatortwitttername8='@tasklong'
                                commentatortwittterurl8='https://twitter.com/tasklong'
                                comment8='アーカイブとして時間が固定された空間に新たに朝と夜の時間軸が与えられているのがいい。切り替えも一瞬ではなく経過が感じられるのがなおいい。現在は音が無く少し寂しいが音が入ればより空間を感じられるだろう。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_9cbf7204-55e0-43ab-bd8b-a264306c9fbc" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">【Mayakan Volumetric】 <a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <br />摩耶観光ホテル跡のフォトグラメトリデータをベースにして、ボリュームレンダリングによって「生きた空気を表現したかった」ことにフォーカスしたワールドです。昼シーンと夜シーンを切り替え可能。夜は夜景も見えます。 <a href="https://t.co/0VbLinwaiX">pic.twitter.com/0VbLinwaiX</a></p>&mdash; VoxelKei (@VoxelKei) <a href="https://twitter.com/VoxelKei/status/1284864198405545984?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">84</div><div className="workinformation"><div className="worktitle">Living space to the space Frontier</div><div className="workname">kanonji</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Living space to the space Frontier'
                                creator='kanonji'
                                number='84'
                                backgroundimage={pic84}
                                worldurl='https://vrchat.com/home/world/wrld_013aa7e0-0996-4bef-9be7-eec557719758'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='宇宙空間に飛び出した時の気持ち良さは、まさにVRだからこそ体験できる気持ち良さのように感じた。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='安全な場所からそうでない場所へのエンゲージを体験させるという趣旨のようだったが、残念ながらその差は実感できなかった。人によるのかもしれないが、安全な場所に安全さをあまり感じることができなかったことが要因と思われる。戦艦らしきジオメトリはかっこいいと思いました。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='何も分からず宇宙空間に放り出され、宇宙難民として孤独を味わうことがこれほど心細い事なのか。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_013aa7e0-0996-4bef-9be7-eec557719758" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">Living space to the space Frontier<br /><br />フロンティアに足を踏み入れる事は希望に満ちていると思うが、それだけじゃないはず。そもそも足を踏み入れる事が出来るのかどうか。安全と思われる囲われた空間から、開いたゲートの先に進む時どんな事を思うかを考えて作りました。<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a></p>&mdash; kanonji (@kanonji) <a href="https://twitter.com/kanonji/status/1284864903291912197?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">85</div><div className="workinformation"><div className="worktitle">浜の路</div><div className="workname">ミチクサ</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='浜の路'
                                creator='ミチクサ'
                                number='85'
                                backgroundimage={pic85}
                                worldurl='https://vrchat.com/home/world/wrld_375435be-2932-4442-b11e-77519d20bc79'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='製作者の中にある終末観のある風景を見ているかのよう。電柱は生活感を出しつつ寂しさも感じるアイテムで悪くないが、心象風景としてはもっと色々見せてほしい。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='作者の心象風景の描写というように解釈をした。たしかに穏やかに佇むにはちょうど良さそうな空間でした。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='浜辺の様で海は見えず、無造作に立ち並んだ電柱はなにを表しているのか。意図した事かわからないが澄んだ水に足を通し砂地を駆け巡っても無音である事が夢の中を彷徨ってるような非現実感があった。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_375435be-2932-4442-b11e-77519d20bc79" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">浜の路<br /><br />穏やかな時が流れるように、心洗われるワールドを目指しました。<br />とにかく丁寧に、精緻な世界になっております。<br /> <a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/w67lAqKGUz">pic.twitter.com/w67lAqKGUz</a></p>&mdash; ミチクサ (@michikusa_xR) <a href="https://twitter.com/michikusa_xR/status/1284864508524019719?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">86</div><div className="workinformation"><div className="worktitle">Apartment model room</div><div className="workname">Karasuma-Kuro</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Apartment model room'
                                creator='Karasuma-Kuro'
                                number='86'
                                backgroundimage={pic86}
                                worldurl='https://vrchat.com/home/world/wrld_4862c181-4313-476d-b4cb-a006a9ac6816'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='小さな可愛らしいお部屋。本当にバーチャル空間に暮らすことがあれば、このくらいの小ささがちょうどいいのかもなと感じました。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='こじんまりとしたお部屋。何かしらの出来事があるとうれしいですね。暮らす、ということへの期待を感じました。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='今目の前にあった部屋は誰かが見た部屋とは違う。絶対に他者とは記憶が交わらない部屋。一期一会より儚いのかもしれない。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_4862c181-4313-476d-b4cb-a006a9ac6816" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a><br /><br />&quot; Apartment model room &quot;<a href="https://t.co/fZFmfLJUoN">https://t.co/fZFmfLJUoN</a><br /><br />小さな部屋のモデルルームをVRChat上に作ってみました。<br />「一期一会」を大事にできればと思いを込めました。<br />小さな部屋ですが「家具をどこに置こうか」なんて思いをはせていただけると幸いです。<a href="https://twitter.com/hashtag/VRChat?src=hash&amp;ref_src=twsrc%5Etfw">#VRChat</a> <a href="https://twitter.com/hashtag/VRChat%E3%83%AF%E3%83%BC%E3%83%AB%E3%83%89%E7%B4%B9%E4%BB%8B?src=hash&amp;ref_src=twsrc%5Etfw">#VRChatワールド紹介</a> <a href="https://t.co/FvDBxeFm5B">pic.twitter.com/FvDBxeFm5B</a></p>&mdash; Karasuma-Kuro@Digital-Creator (@Karasu_ma_Kuro) <a href="https://twitter.com/Karasu_ma_Kuro/status/1284863499533877249?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">87</div><div className="workinformation"><div className="worktitle">LIVE AND LEAVE ～生きることそして別れ～</div><div className="workname">ドコカノうさぎ</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='LIVE AND LEAVE ～生きることそして別れ～'
                                creator='ドコカノうさぎ'
                                number='87'
                                backgroundimage={pic87}
                                worldurl='https://vrchat.com/home/world/wrld_fcab3e0e-8d39-4a8c-a4d7-308386f28cf9'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='バーチャルでの別れ際はいつも独特なさみしさを感じる。とてもバーチャル的な着眼点で面白い。発展性がありそうな予感がする。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='発想は面白いが、不具合があるのか、実装が追いついていない印象を受けた。企画としてはVR生活者らしい発想が光る。実際に多くの人に使われるようになりニーズを受けて更に改良されていくと、一つの文化になるかもしれないと期待を抱いた。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='ログアウトする時は余韻もなく世界から切り離される。そこに別れの演出が挟み込むと急に覚悟の様なものが必要になる感覚があった。余韻のない別れは喪失感に襲われるが、余韻のある別れはその時間が長くなるほど寂寥感が増していくのだと感じた。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_fcab3e0e-8d39-4a8c-a4d7-308386f28cf9" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr"><a href="https://twitter.com/hashtag/VRAA02?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02</a> <a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <br />「LIVE AND LEAVE ～生きることそして別れ～」<br /><br />ログアウトすると残されたがわがとてもさみしいので同時に離れてログアウトできるワールドをつくりました。<br />別れのステージで２人がボタンをおすとステージが別れとおざかり今日のお別れを演出します。 <a href="https://t.co/HkzRuIrIkH">pic.twitter.com/HkzRuIrIkH</a></p>&mdash; ドコカノうさぎ🐰公式VIVEアンバサダー&amp;VR開発系アイドルVtuber (@patsupyon) <a href="https://twitter.com/patsupyon/status/1284865437616889856?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">88</div><div className="workinformation"><div className="worktitle">Chloma Virtual Store -Living In A Bubble-</div><div className="workna"><div className="workname">chloma (鈴木淳哉)</div><div className="award">佳作</div></div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Chloma Virtual Store -Living In A Bubble-'
                                creator='chloma (鈴木淳哉)'
                                number='88'
                                backgroundimage={pic88}
                                worldurl='https://vrchat.com/home/world/wrld_a4e621e9-affc-49f7-947d-5796b4d758d3'
                                class="commentbox"
                                commentator='番匠カンナ'
                                photo={picBanjo}
                                commentatortwitttername='@banjokannna'
                                commentatortwittterurl='https://twitter.com/Banjo_Kanna'
                                comment='バーチャル空間内のブランドショップとしての完成度が高い。VRならではの機能性（例えば14番おもちのびるさんの試着システムなど）や特性をもっと突き詰めた先に、現実の店舗を超える価値が出てくるように思う。'
                                class2="commentbox"
                                commentator2='えこちん'
                                photo2={picEcochin}
                                commentatortwitttername2='@ecochin'
                                commentatortwittterurl2='https://twitter.com/ecochin'
                                comment2='着せ替えワールドでもあり、実際販売されているコンテンツのプロモーションでもあり、アバターとプレイしている本人が重なる体験へ繋がる予感がする。'
                                class3="commentbox"
                                commentator3='sabakichi'
                                photo3={picSabakichi}
                                commentatortwitttername3='@knshtyk'
                                commentatortwittterurl3='https://twitter.com/knshtyk'
                                comment3='ブランドコンセプトを空間的に表現しつつも、プロモーション、実販売、試着等の機能をバランス良く実装しており、プラットフォーム上の制約があるにせよ、現段階でのファッションプロモーションにおけるVR世界の最も完成度の高いユースケースであるように思う。実際に試着している様子が動画にあったが、バーチャル世界の身体でもリアル世界の身体でも様々な着方ができるというのは人類に新たに齎された希望であるし、そこには身体を基準とした新しいアーキテクチャが実在している。'
                                class4="commentbox"
                                commentator4='masanaga'
                                photo4={picMasanage}
                                commentatortwitttername4='@tasklong'
                                commentatortwittterurl4='https://twitter.com/tasklong'
                                comment4='目に見えるものだけでなく、耳から入ってくる情報も含めた空間が丸ごとブランディングされていて、純粋なアパレルショップのVR展開に留まらない空間になっていると感じた。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_a4e621e9-affc-49f7-947d-5796b4d758d3" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">作品名【Chloma Virtual Store -Living In A Bubble- 】<br /><br />VRChat内にオープンした,ファッションブランドchlomaのヴァーチャルストアです.<br /><br />ファッションの「第三の選択肢」を前進させ, 物質とデジタルの境が融けた新しい装いの楽しみ方を作ることを目指しています.<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/Z3mR0UpOOp">pic.twitter.com/Z3mR0UpOOp</a></p>&mdash; chloma | Junya Suzuki (@chlomagears) <a href="https://twitter.com/chlomagears/status/1284865464573636609?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">89</div><div className="workinformation"><div className="worktitle">sector720</div><div className="workname">Jono</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='sector720'
                                creator='Jono'
                                number='89'
                                backgroundimage={pic89}
                                worldurl='https://vrchat.com/home/world/wrld_ca2eef2e-2a2e-444d-9f97-95d40a676e70'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='バーチャルではシンプルな形状でもスケール感を感じやすく、巨大建築の迫力は感じることができたが登ることができず次の一歩へと進めなかった。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='バーチャル世界の立面方向への制限のなさに着目したコンセプトは良いが、残念ながら見えない壁に邪魔をされ階段を使用することができなかったため、体験することができなかった。自由さを持つ建築を空想し、それをビルドできるというところはシンプルにVRらしさを実感できるコンセプトだと感じました。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='作者の意図するところまで到達できず残念。縦の空間を眺めるだけに留まった。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_ca2eef2e-2a2e-444d-9f97-95d40a676e70" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">「sector720」<br />地平線をインターネットの海とVRの境界と見立てて、新しい交流の場を縦方向に構築しました。<a href="https://twitter.com/hashtag/VRAA02%E5%8F%82%E5%8A%A0%E4%BD%9C%E5%93%81?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02参加作品</a> <a href="https://t.co/tqRffWGEfy">pic.twitter.com/tqRffWGEfy</a></p>&mdash; Jono (@saturn014) <a href="https://twitter.com/saturn014/status/1284873315488677890?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                    <div className="work"><FadeIn.Up><div className="information"><div className="number">90</div><div className="workinformation"><div className="worktitle">Time flies, Catch the sky</div><div className="workname">Kluele</div></div></div>
                        <div className="worklist_detail" ref={this.props.setWrapperRef}>
                            <WorklistModal
                                title='Time flies, Catch the sky'
                                creator='Kluele'
                                number='90'
                                backgroundimage={pic90}
                                worldurl='https://vrchat.com/home/world/wrld_57e8bd0c-c6cc-4c06-a6ee-a08e45ba2f7c'
                                class="commentbox"
                                commentator='えこちん'
                                photo={picEcochin}
                                commentatortwitttername='@ecochin'
                                commentatortwittterurl='https://twitter.com/ecochin'
                                comment='所謂タイムラプス撮影したような世界に飛び込んだような非日常感が新鮮。時間を忘れてしまいがちなバーチャル世界で時間という要素に着目するすることで新しいビジュアルを生み出している。'
                                class2="commentbox"
                                commentator2='sabakichi'
                                photo2={picSabakichi}
                                commentatortwitttername2='@knshtyk'
                                commentatortwittterurl2='https://twitter.com/knshtyk'
                                comment2='居心地の良い住居だ。正面からテーマへと回答してくれたものと認識した。こんなところに住みたいと思わせてくれる。それだけで価値がある。'
                                class3="commentbox"
                                commentator3='masanaga'
                                photo3={picMasanage}
                                commentatortwitttername3='@tasklong'
                                commentatortwittterurl3='https://twitter.com/tasklong'
                                comment3='昼夜を気分でカジュアルに変化させることが出来る、時間を操ると言うのは楽しいものなんだと感じさせてくれる。現実であれば太陽の周りを回ってるはずの自分が太陽の位置を好きなように変えられる、まるで天動説を証明するかのように。'
                            /><div className="worklist-button">
                                <a href="https://vrchat.com/home/world/wrld_57e8bd0c-c6cc-4c06-a6ee-a08e45ba2f7c" target="__blank"><button>
                                    ワールドへ行く</button></a>
                            </div>
                        </div><blockquote class="twitter-tweet"><p lang="ja" dir="ltr">『Time flies, Catch the sky』Community Labに登録しました。<br />スフィアを使って太陽の位置を自由に操ったり、高速で時間が経過するモードになります。<br />影が変化していく非日常体験ができます。<br /><br />人数：8人<br />容量：86MB<a href="https://twitter.com/hashtag/VRChat?src=hash&amp;ref_src=twsrc%5Etfw">#VRChat</a> <a href="https://twitter.com/hashtag/VRChat_world%E7%B4%B9%E4%BB%8B?src=hash&amp;ref_src=twsrc%5Etfw">#VRChat_world紹介</a> <a href="https://twitter.com/hashtag/MadeWithVRChat?src=hash&amp;ref_src=twsrc%5Etfw">#MadeWithVRChat</a> <a href="https://twitter.com/hashtag/VRAA02?src=hash&amp;ref_src=twsrc%5Etfw">#VRAA02</a> <a href="https://t.co/bUCWZpwMYo">pic.twitter.com/bUCWZpwMYo</a></p>&mdash; Kluele@VRChat (@Kluele_VRC) <a href="https://twitter.com/Kluele_VRC/status/1284823667419930625?ref_src=twsrc%5Etfw">July 19, 2020</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script></FadeIn.Up></div>
                </div>
                </div>
        );
    }
}

export default Worklist
